import React, {Component} from 'react';
import LazyImage from "../../Base/Lazy";
import PlaceHolder from "../../../images/place-holder.png";
import axios from "axios";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Parser from "html-react-parser";
import helpers from "../../Event/helpers";
import BannerAbout from "../../../images/gt.png";

export default class Partners extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tests:  [],
            setting: '',
            pc: 6,
            mobile: 2
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/partners/`)
            .then(res => {
                const tests = res.data;
                this.setState({ tests: tests });
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/partnersshow/`)
            .then(res => {
                const settings = res.data;
                this.setState({ settings: settings });
                this.setState({ pc: settings[0].pc });
                this.setState({ mobile: settings[0].mobile });
            })
            .catch(error => console.log(error));
    }
    render() {
        SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }

        /*function funcPrev(){
            document.querySelector('.swiper-button-prev').click();
            document.querySelector('.swiper-button-next').click();
        }*/

        return (
            <div className="partners">
                <div className="container">
                    <div className="title center">
                        <h2>Đối tác</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="partners_list">
                        {
                            this.state.tests != ''
                                ? (<Swiper
                                    loop={true}
                                    spaceBetween={50}
                                    slidesPerView={6}
                                    autoplay={{ delay: 5000 }}
                                    navigation={{ clickable: true }}
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1000: {
                                            slidesPerView: 6,
                                        },
                                    }}
                                >
                                    {this.state.tests.map((item, key) => (
                                        <SwiperSlide key={key}>
                                            <div className="insider">
                                                <LazyImage
                                                    placeHolder={PlaceHolder}
                                                    src={item.image}
                                                    width={`100%`}
                                                    height={`auto`}
                                                    effect={"opacity"}
                                                    alt={item.title}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>)
                                : (<div></div>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}
