import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import Logo from "../../images/logo.png";

export default class Page404 extends Component{
    render() {
        return (
            <div className="page404">
                <Helmet>
                    <title>Trang không tồn tại</title>
                    <meta name="description" content="Trang không tồn tại ! Mọi thắc mắc xin vui lòng liên hệ với Takecare Agency theo số điện thoại 0963.339.934 hoặc 0982.339.934. Xin cảm ơn !" />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Trang không tồn tại" />
                    <meta property="og:description" content="Trang không tồn tại ! Mọi thắc mắc xin vui lòng liên hệ với Takecare Agency theo số điện thoại 0963.339.934 hoặc 0982.339.934. Xin cảm ơn !" />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <h1>Trang không tồn tại !</h1>
            </div>
        );
    }
}
