import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import helpers from "../Event/helpers";
import { Link } from "react-router-dom";
import axios from "axios";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Seo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            webs:  [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/website-demo/`)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
            })
            .catch(error => console.log(error));
    }
    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Seo website</title>
                    <meta name="description" content="Chúng tôi chuyên cung cấp dịch vụ SEO website chất lượng, hiệu quả cao. Cam kết hiệu quả sau 6 tháng sử dụng dịch vụ từ khoá của bạn sẽ năm trong top đầu của công cụ tìm kiếm Google." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Seo website" />
                    <meta property="og:description" content="Chúng tôi chuyên cung cấp dịch vụ SEO website chất lượng, hiệu quả cao. Cam kết hiệu quả sau 6 tháng sử dụng dịch vụ từ khoá của bạn sẽ năm trong top đầu của công cụ tìm kiếm Google." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Seo website</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Seo website</h1>
                                    <div className="big">
                                        <p><strong>Seo website</strong> là gì? Nếu bạn đang có những câu hỏi như thế này. Thì tôi dám chắc rằng bạn là người mới trong
                                            lĩnh vực SEO đầy tiềm năng và cũng đầy thử thách này , cùng chào mừng bạn đến với thế giới SEO. Khi bước
                                            chân vào lĩnh vực Website hay lĩnh vực marketing online thì ít nhiều bạn cũng phải biết đến SEO và nhiều
                                            người còn khuyên bạn làm SEO website. Vậy để biết được SEO website là gì thì bạn cần phải có một câu trả
                                            lời chính xác, đầy đủ và không qua loa. Không để tốn thời gian của bạn thêm nữa sau đây chúng ta cùng tìm
                                            hiểu về câu trả lời cụ thể nhất cho bạn.</p>
                                        <Link to="/tin-tuc/seo-website-la-gi-kien-thuc-tong-quan-cua-seo" target="_blank" style={{fontSize:'20px'}}><strong>=> Tìm hiểu chi tiết về Seo website tại đây.</strong></Link>
                                    </div>

                                    <h2 style={{textAlign:'center',marginBottom:'60px',marginTop: '30px',}}>Bảng giá dịch vụ Seo Website</h2>

                                    <div className="pricing">
                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Seo audit</li>
                                                <li className="active_price">6.500.000 VNĐ</li>
                                                <li>- Phân tích website và đối thủ</li>
                                                <li>- Tối ưu Chỉ số website</li>
                                                <li>- Tối ưu thân thiên các phiên bản</li>
                                                <li>- Tối ưu Google check</li>
                                                <li>- Tối ưu SEO Check</li>
                                                <li>- Xây dựng Bảng từ khóa</li>
                                                <li>- Tối ưu Từ khóa</li>
                                                <li>- Tối ưu Mô tả Description</li>
                                                <li>- Tối ưu UI/UX</li>
                                                <li>- Tối ưu các tiện ích checking</li>
                                                <li>- Tối ưu các tiện ích Livechat</li>
                                                <li>- Tối ưu cấu trúc content</li>
                                                <li>- Tối ưu Liên kết Profile social</li>
                                                <li><strong>=> Website tối ưu >85% Theo công cụ SEO Check</strong></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Đăng ký</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">SEO 6 Tháng</li>
                                                <li className="active_price">13.000.000 VNĐ</li>
                                                <li>- Phân tích website và đối thủ</li>
                                                <li>- Tối ưu Chỉ số website</li>
                                                <li>- Tối ưu thân thiên các phiên bản</li>
                                                <li>- Tối ưu Google check</li>
                                                <li>- Tối ưu SEO Check</li>
                                                <li>- Xây dựng Bảng từ khóa</li>
                                                <li>- Tối ưu Từ khóa</li>
                                                <li>- Tối ưu Mô tả Description</li>
                                                <li>- Tối ưu UI/UX</li>
                                                <li>- Tối ưu các tiện ích checking</li>
                                                <li>- Tối ưu các tiện ích Livechat</li>
                                                <li>- Tối ưu cấu trúc content</li>
                                                <li>- Tối ưu Liên kết Profile social</li>
                                                <li>- Xây dựng profile social (Số lượng 100)</li>
                                                <li>- Phát triển 10 kênh social media chính</li>
                                                <li>- Sản xuất và cập content hàng tháng (Số lượng 40)</li>
                                                <li>- Phát triển hệ thông intenal Link</li>
                                                <li>- Phát triển hệ thống Enternal link</li>
                                                <li>- Xây dựng backlink Báo chí (300 Backlink)</li>
                                                <li>- Xây dựng webpost (100 Post)</li>
                                                <li>- Hỗ trợ cập nhật sản phẩm, hình ảnh</li>
                                                <li>- Hỗ trợ sửa lỗi và quản trị website</li>
                                                <li><strong>=> Website tối ưu >85% Theo công cụ SEO Check</strong></li>
                                                <li><strong>=> 50 Từ khóa TOP 10</strong></li>
                                                <li><strong>=> 200 Traffic/ Ngày</strong></li>
                                                <li><strong>=> 500 backlink</strong></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Đăng ký</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">SEO 1 Năm</li>
                                                <li className="active_price">10.400.000 VNĐ</li>
                                                <li>- Phân tích website và đối thủ</li>
                                                <li>- Tối ưu Chỉ số website</li>
                                                <li>- Tối ưu các page, post không giới hạn</li>
                                                <li>- Tối ưu sản phẩm, hình ảnh cũ</li>
                                                <li>- Tối ưu thân thiên các phiên bản</li>
                                                <li>- Tối ưu Google check</li>
                                                <li>- Tối ưu SEO Check</li>
                                                <li>- Xây dựng Bảng từ khóa</li>
                                                <li>- Tối ưu Từ khóa</li>
                                                <li>- Tối ưu Mô tả Description</li>
                                                <li>- Tối ưu UI/UX</li>
                                                <li>- Tối ưu các tiện ích checking</li>
                                                <li>- Tối ưu các tiện ích Livechat</li>
                                                <li>- Tối ưu cấu trúc content</li>
                                                <li>- Tối ưu Liên kết Profile social</li>
                                                <li>- Xây dựng profile social (Số lượng 300)</li>
                                                <li>- Phát triển 20 kênh social media chính</li>
                                                <li>- Sản xuất và cập content hàng tháng (Số lượng 60)</li>
                                                <li>- Phát triển hệ thông intenal Link</li>
                                                <li>- Phát triển hệ thống Enternal link</li>
                                                <li>- Xây dựng backlink Báo chí (600 Backlink)</li>
                                                <li>- Xây dựng webpost (100 Post)</li>
                                                <li>- Hỗ trợ cập nhật sản phẩm, hình ảnh</li>
                                                <li>- Hỗ trợ sửa lỗi và quản trị website</li>
                                                <li><strong>=> Website tối ưu >85% Theo công cụ SEO Check</strong></li>
                                                <li><strong>=> 100 Từ khóa TOP 10</strong></li>
                                                <li><strong>=> 500 Traffic/ Ngày</strong></li>
                                                <li><strong>=> 1000 backlink</strong></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Đăng ký</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <LazyLoadComponent>
                                    <div className="title">
                                        <h2 className="title_ralated">Giao diện demo</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className="list_project">
                                        <div className="in">
                                            {this.state.webs.map((web, key) => (
                                                <div className="item" key={key}>
                                                    <Link to={'/kho-giao-dien/'+web.link}>
                                                        <div className="img">
                                                            <LazyImage
                                                                placeHolder={PlaceHolder}
                                                                src={web.image}
                                                                width={`100%`}
                                                                height={`auto`}
                                                                effect={"opacity"}
                                                                alt={web.title}
                                                            />
                                                        </div>
                                                        <h3><span>{web.title}</span></h3>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </LazyLoadComponent>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
