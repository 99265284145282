import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import google1 from "../../images/chay-quang-cao-google-adwords-co-hieu-qua-khong-1024x576.jpg";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Google extends Component{
    render() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Quảng cáo Google</title>
                    <meta name="description" content="Google được coi là công cụ quảng cáo online trực tuyến hiệu quả nhất thế giới. Chúng tôi chuyên cung cấp dịch vụ chạy quảng cáo google uy tín, chất lượng và hiệu quả cáo." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Quảng cáo Google" />
                    <meta property="og:description" content="Google được coi là công cụ quảng cáo online trực tuyến hiệu quả nhất thế giới. Chúng tôi chuyên cung cấp dịch vụ chạy quảng cáo google uy tín, chất lượng và hiệu quả cáo." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Quảng cáo Google</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Quảng cáo Google</h1>
                                    <div className="big">
                                        <p>Công nghệ thông tin đang phát triển trong những năm gần đây, việc kinh doanh của rất nhiều cá nhân, tổ
                                            đang trở nên rất dễ dàng hơn với mô hình quảng cáo, tiếp thị online. Và Google được coi là công
                                            cụ quảng cáo online trực tuyến hiệu quả nhất trên toàn thế giới. Ngoài tính năng tìm kiếm web cơ bản, Google
                                            Search còn cung cấp 22 dịch vụ miễn phí khác và khiến người sử dụng thực sự hài lòng về những dịch vụ này.
                                            Chính nhờ tính ưu việt của hệ thống tìm kiếm, quảng cáo trên Google thực sự hấp dẫn các khách hàng và trở
                                            thành cầu nối đưa các thông tin sản phẩm tới khách hàng tiềm năng một cách nhanh chóng nhất.</p>
                                        <h2>Quảng cáo Google Adwords là gì?</h2>
                                        <div className="big">
                                            <p>- Google Adwords là hình thức quảng cáo dễ nhất và nhanh nhất để thương hiệu, website, hình ảnh…của bạn
                                                có mặt trên Google và các website liên kết với Google. Quảng cáo Google Adwords với khả năng tùy biến,
                                                linh động trong ngân sách và thông điệp quảng cáo; điều này sẽ giúp định hướng lượng khách hàng tiềm
                                                năng cực kỳ hiệu quả.</p>
                                            <p>- Google Adwords cũng được xem là hình thức quảng cáo tiết kiệm và tìm kiếm khách hàng hiệu quả nhất .
                                                Bởi vì, bạn chỉ phải trả tiền khi khách hàng đó thực sự nhấp chuột vào quảng cáo.Có thể nói ngoài
                                                các dịch vụ Online Marketing khác thì Google Adwords có thể được xem là phương thức truyền tải
                                                thông điệp quảng cáo tiếp cận  nhanh chóng chỉ với hình thức đơn giản.</p>
                                            <p>
                                                <LazyImage
                                                    placeHolder={PlaceHolder}
                                                    src={google1}
                                                    width={`100%`}
                                                    height={`auto`}
                                                    effect={"opacity"}
                                                    alt="Quảng cáo google"
                                                />
                                            </p>
                                        </div>
                                        <h2>Cách thức quảng cáo Googe Adwords:</h2>
                                        <h3>1)   Hình thức quảng cáo</h3>
                                        <p>Tùy theo yêu cầu của Qúy khách hàng, kết quả quảng cáo sẽ được hiển thị vị trí top 1
                                            đến top 3 vị trí đầu tiên hoặc bên tay phải của trang nhất Google. Hình thức hiển thị:</p>
                                        <ul>
                                            <li>Quảng cáo từ khóa trên công cụ tìm kiếm</li>
                                            <li>Quảng cáo từ khóa trên các website</li>
                                            <li>Quảng cáo banner trên các website</li>
                                        </ul>
                                        <h3>2)Hiển thị thông điệp</h3>
                                        <p>Chúng tôi sẽ tối ưu để mẫu quảng cáo có thể xác định đúng đối tượng quan tâm để tối ưu quảng cáo và chi phí như sau:</p>
                                        <ul>
                                            <li>Hiển thị trong khoảng thời gian nhất định</li>
                                            <li>Hiển thị theo đúng từ khóa đã lựa chọn</li>
                                            <li>Hiển thị theo khu vực, quốc gia hoặc toàn cầu</li>
                                            <li>Hiển thị theo đúng định mức ngân sách, theo chiến dịch đã được đặt ra.</li>
                                            <li>Quảng cáo tìm kiếm cho phép xuất hiện đúng lúc khách hàng cần.</li>
                                        </ul>
                                        <h3>3)Cam kết dịch vụ và Bảng giá dịch vụ Google Adwords</h3>
                                        <p>Lợi ích của việc quảng cáo Google Adwords:</p>
                                        <p><strong>Nhắm đúng đối tượng khách hàng</strong></p>
                                        <ul>
                                            <li>Có thể xác định đúng nhóm đối tượng quan tâm tới quảng cáo</li>
                                            <li>Tùy biến giúp khách hàng tối ưu hóa quảng cáo và chi phí</li>
                                            <li>Khả năng tùy biến quảng cáo cực kỳ linh hoạt.</li>
                                            <li>Tiếp  cận số lượng lớn khách hàng trên thế giới cũng như tại Việt Nam.</li>
                                        </ul>
                                        <p><strong>Chi phí thấp, hiệu quả cao</strong></p>
                                        <p>- Kiểm soát chặt chẽ hiệu quả của chiến dịch thông qua hệ thống báo cáo chi tiết.</p>
                                        <p>- Không phụ thuộc vào chi phí ít hay nhiều.</p>
                                        <p>- Linh hoạt, hiệu quả nhanh chóng</p>
                                        <p><strong>Quảng cáo hiển thị nhanh chóng</strong></p>
                                        <ul>
                                            <li>Quảng cáo xuât hiện sau khi set up và chờ duyệt, có thể trong vòng 24h</li>
                                            <li>Thay đổi nội dung quảng cáo, bổ sung từ khóa bất cứ lúc nào bạn muốn.</li>
                                            <li>Quảng cáo với bất cứ ngôn ngữ nào tại bất cứ thị trường nào bạn nhắm tới.</li>
                                        </ul>
                                        <h2>Quy trình dịch vụ  quảng cáo Google Adwords</h2>
                                        <p>Không quên đặt mục tiêu vì lợi ích của khách hàng và dễ dàng hiểu rõ về dịch vụ quảng cáo Google Adwords
                                            và nắm rõ quy trình làm việc của chúng tôi, dưới dây là quy trình dịch vụ quảng cáo Facebook mà chúng tôi cung cấp: </p>
                                        <ul>
                                            <li>Sau khi nhận được thông tin từ khách hàng, chúng tôi sẽ tiến hành phân tích yêu cầu,
                                                khảo sát và báo giá chiến dịch phù hợp.</li>
                                            <li>Gặp gỡ và tư vấn chiến dịch</li>
                                            <li>Ký hợp đồng hợp tác dịch vụ quảng cáo Google Adwords.</li>
                                            <li>Triển khai thực hiện và báo cáo kết quả định kỳ theo quy mô dự án</li>
                                            <li>Nghiệm thu và bàn giao.</li>
                                        </ul>
                                        <p>Đối với khách hàng sử dụng dịch vụ Google Adwords với yêu cầu tự quản lý tài khoản vui lòng trao đổi trước.</p>
                                        <h2><strong>Liên hệ ngay với chúng tôi. Hotline: 0963.339.934</strong></h2>
                                    </div>
                                </div>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
