import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import facebook1 from "../../images/quang-cao-facebook.jpg";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Facebook extends Component{
    render() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Quảng cáo Facebook</title>
                    <meta name="description" content="Bạn muốn thực hiện một chiến dịch quảng cáo Facebook thật hiệu quả. Bạn đang cần tìm một dịch vụ quảng cáo Facebook chuyên nghiệp để bạn có thể cạnh tranh trong thị trường kinh doanh online trên Facebook. Hãy liên hệ ngay với chúng tôi." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Quảng cáo Facebook" />
                    <meta property="og:description" content="Bạn muốn thực hiện một chiến dịch quảng cáo Facebook thật hiệu quả. Bạn đang cần tìm một dịch vụ quảng cáo Facebook chuyên nghiệp để bạn có thể cạnh tranh trong thị trường kinh doanh online trên Facebook. Hãy liên hệ ngay với chúng tôi." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Quảng cáo Facebook</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Quảng cáo Facebook</h1>
                                    <div className="big">
                                        <p>Bạn muốn thực hiện một chiến dịch quảng cáo Facebook thật hiệu quả.
                                            Bạn đang cần tìm một dịch vụ quảng cáo Facebook chuyên nghiệp để bạn có thể cạnh tranh
                                            trong thị trường kinh doanh online trên Facebook.
                                            Và điều quan trọng, bạn muốn khẳng định thương hiệu lớn mạnh. Thì hãy liên hệ ngay với chúng tôi : <strong>0963.339.934</strong></p>
                                    </div>
                                    <h2>Nó là một cơ hội tốt </h2>
                                    <div className="">
                                        <div className="big">
                                            <p>Hãy tận dụng tất cả các mảnh đất kinh doanh màu mỡ chính là thứ không thể bỏ qua và trong đó có Quảng cáo Facebook.
                                                Hãy nói có với Quảng cáo Facebook vì: </p>
                                        </div>

                                        <ul className="ul_big">
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tăng lượng truy cập quảng cáo nhanh</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tăng Like Fanpage hiệu quả</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tăng lượng truy cập website thông qua Fanpage</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Chi phí thấp, linh động - Hiệu quả cao</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tốc độ lan truyền nhanh chóng</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Sở hữu tính năng tương tác với khách hàng tốt nhất hiện nay</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Khả năng hướng đúng đối tượng tiềm năng cao</li>
                                        </ul>

                                        <h2>Lợi ích tuyệt vời của quảng cáo Facebook</h2>
                                        <div className="big">
                                            <p><strong>Chỉ khi thực sự đủ lòng tin vào hiệu quả nó mang lại thì chúng tôi mới khuyên bạn sử dụng nó vì :</strong></p>
                                        </div>
                                        <ul className="ul_big">
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Bạn có thể bán sản phẩm hoặc dịch vụ trực tiếp qua Fanpage của Facebook mà không cần quảng bá trực tiếp trên Website doanh nghiệp.</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Thoải mái sử dụng các hình thức quảng cáo để thu hút sự tương tác và kích thích nhu cầu mua hàng.</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Dịch vụ quảng cáo Facebook có khả năng phân loại đối tượng khách hàng để tiếp cận bằng nhiều hình thức khác nhau.</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Facebook hỗ trợ nhiều tính năng quảng cáo đa dạng hơn như: văn bản, hình ảnh, clip,…</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tương tác ngay lập tức với người tiếp cận quảng để tăng khả năng bán hàng</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Quảng cáo Facebook có thể tiếp cận khách hàng trên mọi thiết bị thông minh</li>
                                            <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tiếp cận khách hàng một cách liên tục, chế độ chăm sóc khách hàng giúp tiết kiệm công sức và chi phí.</li>
                                        </ul>

                                        <p>
                                            <LazyImage
                                                placeHolder={PlaceHolder}
                                                src={facebook1}
                                                width={`100%`}
                                                height={`auto`}
                                                effect={"opacity"}
                                                alt="Quảng cáo google"
                                            />
                                        </p>
                                    </div>

                                    <h2>Chúng tôi cam kết :</h2>
                                    <ul>
                                        <li>Cam kết tăng traffic trên website doanh nghiệp, tăng like và tương tác trên Fanpage nhanh chóng</li>
                                        <li>Xử lý tối ưu các tình huống xảy ra khi quảng cáo Facebook một cách hiệu quả</li>
                                        <li>Hỗ trợ tư vấn hoàn thiện nội dung content trước khi thực hiện quảng cáo Facebook</li>
                                        <li>Giữ được khách hàng tiềm năng và thu hút thêm số lượng lớn khách hàng mới</li>
                                        <li>Chúng tôi luôn đưa ra những chiến dịch quảng cáo mới nhất, hiệu quả nhất để
                                            mang lại hiệu quả tối ưu về mục đích của doanh nghiệp</li>
                                        <li>Cam kết hiệu quả quảng cáo Facebook cho doanh nghiệp như đã ký kết tại hợp đồng</li>
                                        <li>Takecare Agency sở hữu đội ngũ nhân viên kinh nghiệm nhiều năm về SEO, thiết kế web, digital marketing.</li>
                                    </ul>

                                    <p>Đối với khách hàng sử dụng dịch vụ quảng cáo Facebook với yêu cầu tự quản lý tài khoản vui lòng trao đổi trước.</p>
                                    <h2><strong>Liên hệ ngay với chúng tôi. Hotline: 0963.339.934</strong></h2>
                                    <p>Nếu có thể tìm được cho câu trả lời cho những câu hỏi trên thì bạn có thể giúp bạn dễ dàng hơn trong việc tìm
                                        ra ý tưởng, chủ đề cho một bài viết.</p>
                                </div>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
