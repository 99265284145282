import React, {Component} from 'react';
import PlaceHolder from "../../../images/place-holder.png";
import LazyImage from "../../Base/Lazy";
import ImageAside from "../../../images/aside.png";
import BlogItem from "../BlogList/BlogItem";
import axios from 'axios';
import {Link} from "react-router-dom";
import ContactHome from "../Home/ContactHome";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import {Helmet} from "react-helmet";
import Logo from "../../../images/logo.png";

export default class PagiBlog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            posts:  [],
            pagi: [],
            param : props
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/blog/page/`+this.props.match.params.id)
            .then(res => {
                const posts = res.data;
                this.setState({ posts: posts });
                if(posts.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/count-blog/`)
            .then(res => {
                const total = res.data;
                const arr_list = [];
                for(var i = 1; i <= total; i++){
                    arr_list.push(i);
                }
                this.setState({pagi: arr_list});
            })
            .catch(error => console.log(error));
        //this.props.match.params.id
    }

    render() {
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="blog_list">
                <Helmet>
                    <title>Tin tức | Các tin tức mới nhất của Takecare Agency</title>
                    <meta name="description" content="Các tin tức mới nhất của Takecare Agency nói về lĩnh vực thiết kế website, nhận diện thương hiệu, SEO website, biên soạn nội dung, chạy quảng cáo google, facebook ..." />
                    <meta name="keywords" content="Các chương trình khuyến mãi đặc biệt nhằm chi ân khách hàng đã tin tưởng và sử dụng sản phẩm, dich vụ của Takecare Agency trong thời gian vừa qua. Với những chương trình ưu đãi hấp dẫn nhất mong quý khách không bỏ lỡ..." />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Tin tức | Các tin tức mới nhất của Takecare Agency" />
                    <meta property="og:description" content="Các tin tức mới nhất của Takecare Agency nói về lĩnh vực thiết kế website, nhận diện thương hiệu, SEO website, biên soạn nội dung, chạy quảng cáo google, facebook ..." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Tin tức</span></li>
                        </ul>
                    </div>

                    <div className="banner">
                        <div className="in">
                            <div className="img">
                                <LazyImage
                                    placeHolder={PlaceHolder}
                                    src={'https://api.takecare.vn/takecare/files/2021/02/qc9.jpg'}
                                    width={`100%`}
                                    height={`auto`}
                                    effect={"opacity"}
                                    alt="Takecare Agency"
                                />
                            </div>
                            <div className="img">
                                <LazyImage
                                    placeHolder={PlaceHolder}
                                    src={'https://api.takecare.vn/takecare/files/2021/02/5-mau-thiet-ke-brochure-dep-doc-la-nhat-nam-2020-1-768x457.jpg'}
                                    width={`100%`}
                                    height={`auto`}
                                    effect={"opacity"}
                                    alt="Takecare Agency"
                                />
                            </div>
                        </div>
                    </div>
                    <h1 style={{position:"absolute",top:"-1000px",opacity:'0'}}>Tin tức | Các tin tức mới nhất của Takecare Agency</h1>
                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="cate">
                                    <ul>
                                        <li className="active"><Link to="/tin-tuc">Tin tức</Link></li>
                                        <li><Link to="/khuyen-mai">Khuyến mãi</Link></li>
                                    </ul>
                                </div>

                                <div className="blogs">
                                    {this.state.posts.map((post, key) => (
                                        <BlogItem
                                            key={key}
                                            slug={post.link}
                                            img={post.image}
                                            alt={post.title}
                                            title={post.title}
                                            cate="Tin tức"
                                            content={post.description}
                                            moth_day="Feb 17"
                                            year="2020"
                                            cat={post.cat}
                                        />
                                    ))}
                                </div>

                                <div className="pagi">
                                    <ul>
                                        {this.state.pagi.map((item, key) => (
                                            this.props.match.params.id == ''
                                            ?
                                                (<li key={item}><span>{key + 1}</span></li>)
                                            :
                                            this.props.match.params.id == key + 1
                                            ? (<li key={item}><span>{key + 1}</span></li>)
                                            : <li key={item}><Link to={'/tin-tuc/page/'+(key+1)} onClick={LoadPage}>{key+1}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="ite">
                                <aside>
                                    <div className="widget">
                                        <div className="title">
                                            <h2>Tư vấn dịch vụ</h2>
                                            <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>

                                        <div className="contact_form">
                                            <LazyImage
                                                placeHolder={PlaceHolder}
                                                src={ImageAside}
                                                width={`100%`}
                                                height={`auto`}
                                                effect={"opacity"}
                                                alt="123"
                                            />
                                            <ul>
                                                <li>
                                                    <input type="text" className="name" placeholder="Họ Và Tên" />
                                                </li>
                                                <li>
                                                    <input type="text" className="email" placeholder="Email" />
                                                </li>
                                                <li>
                                                    <input type="text" className="tel" placeholder="Số điện thoại" />
                                                </li>
                                                <li>
                                                    <textarea className="khac" placeholder="Ghi chú thêm"></textarea>
                                                </li>
                                                <li>
                                                    <button type="button" className="send">Tư vấn ngay<i
                                                        className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoadComponent>
                    <ContactHome />
                </LazyLoadComponent>
            </div>
        );
    }
}
