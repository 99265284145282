import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Gua extends Component{
    render() {
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Chính sách bảo hành</title>
                    <meta name="description" content="Chính sách bảo hành và các quy định về đổi trả sản phẩm được công ty công khai minh bạch với cá nhân hoặc tổ chức khi sử dụng sản phẩm và dịch vụ của Takecare Agency" />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Chính sách bảo hành" />
                    <meta property="og:description" content="Chính sách bảo hành và các quy định về đổi trả sản phẩm được công ty công khai minh bạch với cá nhân hoặc tổ chức khi sử dụng sản phẩm và dịch vụ của Takecare Agency" />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Chính sách bảo hành</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Chính sách bảo hành</h1>
                                    <div className="big">
                                        <p>Chúng tôi cam kết chính sách này với toàn bộ khách hàng đang sử dụng dịch vụ của <strong>Takecare Agency</strong></p>
                                        <p>Điều kiện bảo hành: Takecare Agency cam kết bảo hành các lỗi phát sinh trong quá trình quý khách hàng sử dụng dịch vụ.</p>
                                        <h2>1. Thiết kế thương hiệu</h2>
                                        <p>- Thời gian bảo hành:</p>
                                        <p>+ Trọn đời nếu khách bị mất file thiết kế gốc sau khi bàn giao, Takecare Agency sẽ khôi phục và bàn giao lại cho khách hoàn toàn miễn phí.</p>
                                        <p>+ Tối thiểu 1 tháng về màu sắc hoặc độ bền vững của sản phẩm khi sử dụng.</p>
                                        <h2>2. Thiết kế web</h2>
                                        <p>– Thời gian bảo hành:</p>
                                        <p>+ Vĩnh viễn với các khách hàng sử dụng dịch vụ thiết kế web kèm hosting tại Takecare Agency.</p>
                                        <p>+ Tối thiểu 1 tháng với khách hàng sử dụng dịch vụ thiết kế web và sử dụng hosting của nhà cung cấp khác,
                                            sau 1 tháng Takecare Agency vẫn sẽ hỗ trợ backup dữ liệu nếu website gặp vấn đề, khách hàng sẽ cung cấp thông
                                            tin cần để Takecare Agency có thể truy cập vào hosting.</p>
                                        <h2>Marketing Online</h2>
                                        <p>- Bảo hành miễn phí về nội dung và hình ảnh nếu ngôn từ, hình ảnh không phù hợp hoặc vi phạm bởi chính sách của bên thứ 3</p>
                                        <p>+ Email tiếp nhận hỗ trợ: <strong>info@takecare.vn</strong></p>
                                        <p>+ Hotline hỗ trợ: <strong>0963.339.934</strong> – <strong>0982.339.934</strong></p>
                                        <p>+ Website: takecare.vn</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ite">
                                <aside>
                                    <div className="widget">
                                        <div className="title">
                                            <h2>Tư vấn dịch vụ</h2>
                                            <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>

                                        <div className="contact_form">
                                            <LazyImage
                                                placeHolder={PlaceHolder}
                                                src={ImageAside}
                                                width={`100%`}
                                                height={`auto`}
                                                effect={"opacity"}
                                                alt="Liên hệ"
                                            />
                                            <ul>
                                                <li>
                                                    <input type="text" className="name" placeholder="Họ Và Tên" />
                                                </li>
                                                <li>
                                                    <input type="text" className="email" placeholder="Email" />
                                                </li>
                                                <li>
                                                    <input type="text" className="tel" placeholder="Số điện thoại" />
                                                </li>
                                                <li>
                                                    <textarea className="khac" placeholder="Ghi chú thêm"></textarea>
                                                </li>
                                                <li>
                                                    <button type="button" className="send">Tư vấn ngay<i
                                                        className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
