import React, {Component} from 'react';
import axios from "axios";

export default class ContactHome extends Component{
    constructor(props, context) {
        super(props, context);
        this.state = {
            fullname: '',
            email: '',
            tel: '',
            service: '',
            note: '',
            phone1: '',
            phone_call1: '',
            email1: '',
            phone2: '',
            phone_call2: '',
            email2: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/phone-api/`)
            .then(res => {
                const phone_list = res.data;
                this.setState({ phone1: phone_list[0].phone1 });
                this.setState({ phone_call1: phone_list[0].phone_call1 });
                this.setState({ email1: phone_list[0].email1 });
                this.setState({ phone2: phone_list[0].phone2 });
                this.setState({ phone_call2: phone_list[0].phone_call2 });
                this.setState({ email2: phone_list[0].email2 });
            })
            .catch(error => console.log(error));
    }

    handleChangeFullName = event => {
        this.setState({ fullname: event.target.value })
    }

    handleChangeEmail = event => {
        this.setState({ email: event.target.value })
    }

    handleChangeTel = event => {
        this.setState({ tel: event.target.value })
    }

    handleChangeService = event => {
        this.setState({ service: event.target.value })
    }

    handleChangeNote = event => {
        this.setState({ note: event.target.value })
    }

    async handleSubmit(e) {
        e.preventDefault();

        var data = new FormData();

        if(this.state.fullname != ''){
            data.append('fullname', this.state.fullname);
        }else{
            let slt = document.querySelector('.contact_home .in .item:nth-child(2) ul li input.name');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Tên bị trống !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        if(this.state.email != ''){
            function validateEmail(email)
            {
                var re = /\S+@\S+\.\S+/;
                return re.test(email);
            }

            if(validateEmail(this.state.email) == true){
                data.append('email', this.state.email);
            }else{
                let slt = document.querySelector('.contact_home .in .item:nth-child(2) ul li input.email');
                slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Email không hợp lệ !</p>');
                setTimeout(function (){
                    document.querySelector('.empty_txt').remove();
                },1500);
            }
        }else{
            let slt = document.querySelector('.contact_home .in .item:nth-child(2) ul li input.email');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Email bị trống !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        if(this.state.tel != ''){
            function telephoneCheck(str) {
                return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(str);
            }
            if(telephoneCheck(this.state.tel) == true){
                data.append('tel', this.state.tel);
            }else{
                let slt = document.querySelector('.contact_home .in .item:nth-child(2) ul li input.tel');
                slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Số điện thoại không hợp lệ !</p>');
                setTimeout(function (){
                    document.querySelector('.empty_txt').remove();
                },1500);
            }
        }else{
            let slt = document.querySelector('.contact_home .in .item:nth-child(2) ul li input.tel');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Số điện thoại bị trống !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        if(this.state.service != ''){
            data.append('service', this.state.service);
        }else{
            let slt = document.querySelector('.contact_home .in .item:nth-child(2) ul li select');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Chưa chọn dịch vụ !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        data.append('note', this.state.note);

        if(this.state.fullname != '' && this.state.email != '' && this.state.tel != '' && this.state.service != ''){
            data.append('send', 'true');
            data.append('form', 'Form Footer');
            axios.post('https://api.takecare.vn/form-data/', data)
                .then(response => {
                    //console.log(response)
                    //this.filter = response.data
                })
                .catch(e => {
                    //console.log(e)
                });
            this.setState({fullname: ''});
            this.setState({email: ''});
            this.setState({tel: ''});
            this.setState({service: ''});
            this.setState({note: ''});

            document.querySelector('.contact_home .in .item:nth-child(2) ul li input.name').value = '';
            document.querySelector('.contact_home .in .item:nth-child(2) ul li input.email').value = '';
            document.querySelector('.contact_home .in .item:nth-child(2) ul li input.tel').value = '';
            document.querySelector('.contact_home .in .item:nth-child(2) ul li select.dv').value = '';
            document.querySelector('.contact_home .in .item:nth-child(2) ul li textarea.khac').value = '';
            document.querySelector('.thankyou').classList.add('active');

            function setCookie (key, value, days) {
                var date = new Date();

                // Default at 365 days.
                days = days || 365;

                // Get unix milliseconds at current time plus number of days
                date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000

                window.document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";

                return value;
            };

            let getCookie = function(name) {
                var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
                return r ? r[1] : null;
            };

            if(getCookie('checkform') == 'NaN'){
                setCookie('checkform', 1, 1);
            }else{
                let n = parseInt(getCookie('checkform')) + 1;
                setCookie('checkform', n, 1);
            }
        }
    }

    render() {
        let getCookie = function(name) {
            var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
            return r ? r[1] : null;
        };
        return (
            <div className="contact_home">
                <div className="container">
                    <div className="in">
                        <div className="item">
                            <div className="title left">
                                <h2>Tư vấn dịch vụ</h2>
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                            <div className="des_">Liên lạc ngay với chúng tôi để được tư vấn.</div>

                            <div className="lh">
                                <ul>
                                    <li>
                                        <i className="fa fa-globe" aria-hidden="true"></i>
                                        <span>TP. Hồ Chí Minh</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        <span>{this.state.email1}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <span>{this.state.phone1}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="lh">
                                <ul>
                                    <li>
                                        <i className="fa fa-globe" aria-hidden="true"></i>
                                        <span>TP. Hà Nội</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        <span>{this.state.email2}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <span>{this.state.phone2}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="item">
                            <div className="content">
                                <div className="h2">Để lại thông tin liên hệ</div>
                                <ul>
                                    <li>
                                        <input type="text" className="name" placeholder="Họ Và Tên" onChange={this.handleChangeFullName}/>
                                    </li>
                                    <li>
                                        <input type="text" className="email" placeholder="Email" onChange={this.handleChangeEmail}/>
                                    </li>
                                    <li>
                                        <input type="text" className="tel" placeholder="Số điện thoại" onChange={this.handleChangeTel}/>
                                    </li>
                                    <li>
                                        <select className="dv" onChange={this.handleChangeService}>
                                            <option value="">Chọn dịch vụ tư vấn</option>
                                            <option value="Thiết kế website">Thiết kế website</option>
                                            <option value="Thiết kế logo">Thiết kế logo</option>
                                            <option value="SEO website">SEO website</option>
                                            <option value="Chạy quảng cáo">Chạy quảng cáo</option>
                                            <option value="Viết bài nội dung">Viết bài nội dung</option>
                                            <option value="Khác">Khác</option>
                                        </select>
                                    </li>
                                    <li>
                                        <textarea className="khac" placeholder="Ghi chú thêm" onChange={this.handleChangeNote}></textarea>
                                    </li>
                                    <li>
                                        {(() => {
                                            if (getCookie('checkform') >= 2) {
                                                return (
                                                    <button type="button" className="send" name="send" style={{backgroundColor:'#eaeaea'}}>
                                                        Tư vấn ngay
                                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </button>
                                                )
                                            } else {
                                                return (
                                                    <button type="submit" className="send" name="send" onClick={this.handleSubmit}>
                                                        Tư vấn ngay
                                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </button>
                                                )
                                            }
                                        })()}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
