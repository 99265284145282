import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import helpers from "../Event/helpers";
import axios from "axios";
import {Link} from "react-router-dom";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Care extends Component{
    constructor(props) {
        super(props);
        this.state = {
            webs:  [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/website-demo/`)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
            })
            .catch(error => console.log(error));
    }
    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Chăm sóc website</title>
                    <meta name="description" content="Dịch vụ Chăm sóc website của chúng tôi đa dạng với nhiều gói linh hoạt để quý khách hàng chọn lựa, tùy theo khả năng về đầu tư kinh phí của bạn chúng tôi sẽ tư vấn cho bạn sử dụng một gói phù hợp." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Chăm sóc website" />
                    <meta property="og:description" content="Dịch vụ Chăm sóc website của chúng tôi đa dạng với nhiều gói linh hoạt để quý khách hàng chọn lựa, tùy theo khả năng về đầu tư kinh phí của bạn chúng tôi sẽ tư vấn cho bạn sử dụng một gói phù hợp." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Chăm sóc website</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Chăm sóc website</h1>
                                    <div className="big">
                                        <p>- Đa phần các doanh nghiệp khi xây dựng xong website không hoàn thiện đầy đủ nội dung
                                            hoặc chỉ hoàn thiện nội dung ban đầu của website cũng như hệ thống quảng cáo website.
                                            Như vậy tính mới lạ tính mới của thông tin chỉ dừng lại ở thời điểm xây dựng website.Việc
                                            này dẫn đến một ấn tượng nội dung không tốt , mất thiện cảm với khách hàng , khách hàng bỏ
                                            đi và không quan tâm nữa điều không một doanh nghiệp nào muốn gặp phải.</p>
                                        <p>- Dịch vụ Chăm Sóc Website của chúng tôi đa dạng với nhiều gói linh hoạt để quý khách hàng chọn lựa,
                                            tùy theo khả năng về đầu tư kinh phí của bạn chúng tôi sẽ tư vấn cho bạn sử dụng một gói phù hợp :</p>
                                    </div>
                                    <ul className="ul_big">
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Kiểm tra hoạt đông của web</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Xử lý sự cố liên quan</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Sao lưu dữ liệu</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Cài đặt web master tools, google analytic</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Đăng bài chuẩn SEO theo lĩnh vực hoạt động</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Đăng sản phẩm theo yêu cầu.</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Thiết kế banner chương trình.</li>
                                    </ul>

                                    <h2 style={{textAlign: 'center',marginBottom:'30px',}}>Bảng giá dịch vụ</h2>
                                    <div className="pricing">
                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Basic</li>
                                                <li className="active_price">990.000 VNĐ</li>
                                                <li>Kiểm tra hoạt đông của web <i className="fa fa-check-circle"
                                                                                    aria-hidden="true"></i></li>
                                                <li>Xử lý sự cố liên quan <i className="fa fa-check-circle"
                                                                                                       aria-hidden="true"></i></li>
                                                <li>Sao lưu dữ liệu <i className="fa fa-check-circle"
                                                                           aria-hidden="true"></i></li>
                                                <li>Cài đặt web master tools, google analytic <i className="fa fa-check-circle"
                                                                                                 aria-hidden="true"></i></li>
                                                <li>Đăng bài chuẩn SEO theo lĩnh vực hoạt động ( 2 bài / tuần ) <i className="fa fa-check-circle"
                                                                                      aria-hidden="true"></i></li>
                                                <li>Đăng sản phẩm theo yêu cầu (30sp / tháng ) <i className="fa fa-check-circle"
                                                                                aria-hidden="true"></i></li>
                                                <li>Thiết kế banner chương trình ( 1 sản phẩm ) <i className="fa fa-check-circle"
                                                                                aria-hidden="true"></i></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Đăng ký</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Pro</li>
                                                <li className="active_price">1.990.000 VNĐ</li>
                                                <li>kiểm tra hoạt động của web <i className="fa fa-check-circle"
                                                                                    aria-hidden="true"></i></li>
                                                <li>Xử lý sự cố liên quan <i className="fa fa-check-circle"
                                                                                                       aria-hidden="true"></i></li>
                                                <li>Sao lưu dữ liệu <i className="fa fa-check-circle"
                                                                           aria-hidden="true"></i></li>
                                                <li>Cài đặt web master tools , google analytic <i className="fa fa-check-circle"
                                                                                                              aria-hidden="true"></i></li>
                                                <li>Đăng bài chuẩn SEO theo lĩnh vực hoạt động ( 4 bài / tuần ) <i className="fa fa-check-circle"
                                                                                      aria-hidden="true"></i></li>
                                                <li>Đăng sản phẩm theo yêu cầu (50sp/ tháng ) <i className="fa fa-check-circle"
                                                                                  aria-hidden="true"></i></li>
                                                <li>Thiết kế banner chương trình ( 1 sản phẩm ) <i className="fa fa-check-circle"
                                                                                  aria-hidden="true"></i></li>
                                                <li>Viết bài chia sẻ trên facebook ( 4 bài ) <i className="fa fa-check-circle"
                                                                                  aria-hidden="true"></i></li>
                                                <li>Chia sẻ trên các diễn đàn ( 10 trang ) <i className="fa fa-check-circle"
                                                                                  aria-hidden="true"></i></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Đăng ký</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Premium</li>
                                                <li className="active_price">2.990.000 VNĐ</li>
                                                <li>kiểm tra hoạt động của web <i className="fa fa-check-circle"
                                                                                  aria-hidden="true"></i></li>
                                                <li>Xử lý sự cố liên quan <i className="fa fa-check-circle"
                                                                             aria-hidden="true"></i></li>
                                                <li>Sao lưu dữ liệu <i className="fa fa-check-circle"
                                                                       aria-hidden="true"></i></li>
                                                <li>Cài đặt web master tools , google analytic <i className="fa fa-check-circle"
                                                                                                  aria-hidden="true"></i></li>
                                                <li>Đăng bài chuẩn SEO theo lĩnh vực hoạt động ( 7 bài / tuần ) <i className="fa fa-check-circle"
                                                                                                                                    aria-hidden="true"></i></li>
                                                <li>Đăng sản phẩm theo yêu cầu (70sp/ tháng ) <i className="fa fa-check-circle"
                                                                                                                  aria-hidden="true"></i></li>
                                                <li>Thiết kế banner chương trình ( 2 sản phẩm ) <i className="fa fa-check-circle"
                                                                                                                    aria-hidden="true"></i></li>
                                                <li>Viết bài chia sẻ trên facebook ( 10 bài ) <i className="fa fa-check-circle"
                                                                                                                 aria-hidden="true"></i></li>
                                                <li>Chia sẻ trên các diễn đàn ( 20 trang ) <i className="fa fa-check-circle"
                                                                                                               aria-hidden="true"></i></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Đăng ký</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <LazyLoadComponent>
                                    <div className="title">
                                        <h2 className="title_ralated">Giao diện demo</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className="list_project">
                                        <div className="in">
                                            {this.state.webs.map((web, key) => (
                                                <div className="item" key={key}>
                                                    <Link to={'/kho-giao-dien/'+web.link}>
                                                        <div className="img">
                                                            <LazyImage
                                                                placeHolder={PlaceHolder}
                                                                src={web.image}
                                                                width={`100%`}
                                                                height={`auto`}
                                                                effect={"opacity"}
                                                                alt={web.title}
                                                            />
                                                        </div>
                                                        <h3><span>{web.title}</span></h3>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </LazyLoadComponent>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
