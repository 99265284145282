import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class  Contact extends Component{
    render() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="page_contact">
                <Helmet>
                    <title>Liên hệ với chúng tôi</title>
                    <meta name="description" content="Chúng tôi chuyên cung cấp dịch vụ thiết kế website, bảo trì website, seo website, viết nội dung, chạy quảng cáo google, chạy quảng cáo facebook, thiết kế bộ nhận diện thương hiệu đáp ứng mọi yêu cầu của khách hàng. Liên hệ ngay với chúng tôi để được hỗ trợ !" />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Liên hệ với chúng tôi" />
                    <meta property="og:description" content="Chúng tôi chuyên cung cấp dịch vụ thiết kế website, bảo trì website, seo website, viết nội dung, chạy quảng cáo google, chạy quảng cáo facebook, thiết kế bộ nhận diện thương hiệu đáp ứng mọi yêu cầu của khách hàng. Liên hệ ngay với chúng tôi để được hỗ trợ !" />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Liên hệ với chúng tôi</span></li>
                        </ul>
                    </div>
                    <h1 style={{position:'absolute',opacity:'0',top:'-1000px'}}>Liên hệ với chúng tôi</h1>
                </div>

                <LazyLoadComponent>
                    <div style={{marginBottom:'15px'}}>
                        <ContactHome />
                    </div>
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Why />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Partners />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Process />
                </LazyLoadComponent>
            </div>
        );
    }
}
