import React, {Component} from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Slider from "./Home/Slider";
import Service from "./Home/Service";
import AboutHome from "./Home/AboutHome";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import Blog from "./Home/Blog";
import axios from "axios";
import ItemDesign from "./Design/ItemDesign";
import {Link} from "react-router-dom";
import ContactHome from "./Home/ContactHome";
import PlaceHolder from "../../images/place-holder.png";
import LazyImage from "../Base/Lazy";
import Partners from "./Home/Partners";
import {Helmet} from "react-helmet";
import Logo from '../../images/logo.png';

export default class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            designs:  [],
            webs:  [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/list-branding/`)
            .then(res => {
                const designs = res.data;
                this.setState({ designs: designs });
            })
            .catch(error => console.log(error));

        axios.get(`https://api.takecare.vn/show-website-home/`)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
            })
            .catch(error => console.log(error));
    }
    render() {
        return (
            <div className="home">
                <Helmet>
                    <title>Takecare Agency | Chuyên Thiết kế website, nhận diện thương hiệu...</title>
                    <meta name="description" content="Chúng tôi chuyên cung cấp dịch vụ thiết kế thương hiệu, thiết kế website, bảo trì website, seo website, viết nội dung, chạy quảng cáo google, chạy quảng cáo facebook đáp ứng mọi yêu cầu của khách hàng." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content="https://takecare.vn" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Takecare Agency | Chuyên Thiết kế website, nhận diện thương hiệu..." />
                    <meta property="og:description" content="Chúng tôi chuyên cung cấp dịch vụ thiết kế thương hiệu, thiết kế website, bảo trì website, seo website, viết nội dung, chạy quảng cáo google, chạy quảng cáo facebook đáp ứng mọi yêu cầu của khách hàng." />
                    <meta property="og:image" content={Logo} />
                </Helmet>
                <h1>Takecare Agency | Chuyên Thiết kế website, nhận diện thương hiệu...</h1>
                <LazyLoadComponent>
                    <Slider />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <Service />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <AboutHome />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <Why />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <div className="title">
                        <h2>Các thương hiệu hiệu đã triển khai</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="list_brand_home">
                        <div className="list_brand">
                            {this.state.designs.map((design, key) => (
                                <ItemDesign
                                    key={key}
                                    title={design.title}
                                    slug={design.link}
                                    image={design.image}
                                />
                            ))}
                        </div>

                        <div className="blog">
                            <div className="btn">
                                <Link target='_blank' to="/thuong-hieu"><i className="fa fa-plus" aria-hidden="true"></i> Xem thêm</Link>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <div className="container">
                        <div className="title">
                            <h2>Kho giao diện đa dạng</h2>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>

                        <div className="list_project list_project_home">
                            <div className="in">
                                {this.state.webs.map((web, key) => (
                                    <div className="item" key={key}>
                                        <a href={'/kho-giao-dien/'+web.link}>
                                            <div className="img">
                                                <LazyImage
                                                    placeHolder={PlaceHolder}
                                                    src={web.image}
                                                    width={`100%`}
                                                    height={`auto`}
                                                    effect={"opacity"}
                                                    alt={web.title}
                                                />
                                            </div>
                                            <h3><span>{web.title}</span></h3>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <div className="blog">
                                <div className="btn">
                                    <Link  target='_blank' to="/kho-giao-dien"><i className="fa fa-plus" aria-hidden="true"></i> Xem thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <Process />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <Blog />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <Partners />
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <ContactHome />
                </LazyLoadComponent>
            </div>
        );
    }
}
