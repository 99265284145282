import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import Sale30 from "../../images/sale30.png";
import Sale302 from "../../images/sale302.png";
import Sale303 from "../../images/sale303.png";
import helpers from "../Event/helpers";
import {Link} from "react-router-dom";
import axios from "axios";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import Image from "../../images/img1.jpg";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class websitePro extends Component{
    constructor(props) {
        super(props);
        this.state = {
            webs:  [],
            webs_home:  [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/website-demo/`)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/web-demo/`)
            .then(res => {
                const webs_home = res.data;
                this.setState({ webs_home: webs_home });
            })
            .catch(error => console.log(error));
    }
    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Thiết kế website</title>
                    <meta name="description" content="Thiết kế website chuyên nghiệp thể hiện ở giao diện sáng tạo, độc quyền với các hiệu ứng chuyển động tinh tế, hiệu suất cao, tối ưu SEO giúp khách hàng có trải nghiệm tốt và đem đến nhiều giá trị cho doanh nghiệp của bạn. Liên hệ ngay với chúng tôi để được hỗ trợ thêm." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Thiết kế website" />
                    <meta property="og:description" content="Thiết kế website chuyên nghiệp thể hiện ở giao diện sáng tạo, độc quyền với các hiệu ứng chuyển động tinh tế, hiệu suất cao, tối ưu SEO giúp khách hàng có trải nghiệm tốt và đem đến nhiều giá trị cho doanh nghiệp của bạn. Liên hệ ngay với chúng tôi để được hỗ trợ thêm." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="banner_page first_banner_"  style={{ background: 'url(' + Image + ')' }}>
                    <div className="container">
                        <div className="first_banner">
                            <h1>Thiết kế website</h1>
                            <p className="des_banner">Thiết kế website chuyên nghiệp thể hiện ở giao diện sáng tạo, độc quyền với các hiệu ứng chuyển động tinh tế, hiệu suất cao, tối ưu SEO <br /> giúp khách hàng có trải nghiệm tốt và đem đến nhiều giá trị cho doanh nghiệp của bạn.</p>
                        </div>
                    </div>
                </div>

                <LazyLoadComponent>
                    <div className="container">
                        <div className="service_web">
                            <div className="title center">
                                <h2>Nghành nghề đa dạng</h2>
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                            <div className="list">
                                <div className="item">
                                    <i className="fa fa-diamond" aria-hidden="true"></i>
                                    <h3>Theo thương hiệu</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-television" aria-hidden="true"></i>
                                    <h3>Giới thiệu</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                                    <h3>Thương mại điện tử</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                                    <h3>Tin tức</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-building-o" aria-hidden="true"></i>
                                    <h3>Bất động sản</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-building" aria-hidden="true"></i>
                                    <h3>Du lịch - khách sạn</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-bed" aria-hidden="true"></i>
                                    <h3>Kiến trúc - nội thất</h3>
                                </div>
                                <div className="item">
                                    <i className="fa fa-bar-chart" aria-hidden="true"></i>
                                    <h3>Landing Page</h3>
                                </div>
                            </div>
                            <div className="btn">
                                <i className="fa fa-angle-double-down" aria-hidden="true"></i>
                                <a href="/" className="popup_contact" onClick={PopupContact}><i className="fa fa-envelope-open" aria-hidden="true"></i> Tư vấn ngay</a>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <div className="list_create_logo list_create_web">
                        <div className="container">
                            <div className="branding_page">
                                <div className="title center">
                                    <h2>Bảng giá thiết kế website</h2>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                                <div className="box">
                                    <div className="item">
                                        <h3>LANDING PAGE</h3>
                                        <ul>
                                            <li style={{ display: 'none' }}><span>1.590.000 VNĐ</span></li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thời gian : 2 - 3 ngày</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện : Theo yêu cầu</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Tặng tên miền</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Tặng hosting</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Tặng gói thiết kế logo</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Tặng kèm banner</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Hỗ trợ làm nội dung</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo hành trọn đời</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện tương thích mobile</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Website chuẩn SEO onepage</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo mật SSL: miễn phí</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao bộ code</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Hoàn trả 200% giá trị hợp đồng khi không đáp ứng đúng yêu cầu như đã cam kết</li>
                                            {/*<li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>10%</span> khi đăng ký gói <a
                                                href="thiet-ke-logo">thiết kế logo</a></li>*/}
                                            <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                        </ul>
                                    </div>
                                    <div className="item active">
                                        <h3>Gói A</h3>
                                        <ul>
                                            <li style={{ display: 'none' }}><span>5.990.000 VNĐ</span></li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thời gian : 5 - 7 ngày</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện : Theo yêu cầu</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng tên miền : .com</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng hosting : 2GB</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng gói thiết kế logo</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Tặng kèm banner</li>
                                            <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Hỗ trợ làm nội dung</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo hành trọn đời</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện tương thích mobile</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Website chuẩn SEO onepage</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo mật SSL: miễn phí</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao bộ code</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Hoàn trả 200% giá trị hợp đồng khi không đáp ứng đúng yêu cầu như đã cam kết</li>
                                            {/*<li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>10%</span> khi đăng ký gói <a
                                                href="thiet-ke-logo">thiết kế logo</a></li>*/}
                                            <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                        </ul>
                                    </div>
                                    <div className="item">
                                        <h3>Gói B</h3>
                                        <ul>
                                            <li style={{ display: 'none' }}><span>10.990.000 VNĐ</span></li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thời gian : 10 - 15 ngày</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện : Theo yêu cầu</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng tên miền tự chọn</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng hosting : 3GB</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng gói thiết kế logo</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng kèm banner : 3</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Hỗ trợ làm nội dung hình ảnh</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo hành trọn đời</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện tương thích mobile</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Website chuẩn SEO onepage</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo mật SSL: miễn phí</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao bộ code</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Hoàn trả 200% giá trị hợp đồng khi không đáp ứng đúng yêu cầu như đã cam kết</li>
                                            {/*<li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>15%</span> khi đăng ký gói <a
                                                href="thiet-ke-logo">thiết kế logo</a></li>*/}
                                            <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                        </ul>
                                    </div>
                                    <div className="item">
                                        <h3>Gói C</h3>
                                        <ul>
                                            <li style={{ display: 'none' }}><span>15.990.000 VNĐ</span></li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thời gian : >= 25 ngày</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện : Thiết kế độc quyền</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng tên miền tự chọn</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng hosting : 5GB</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng gói thiết kế logo</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Tặng kèm banner : 5</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Hỗ trợ làm nội dung hình ảnh</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo hành trọn đời</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giao diện tương thích mobile</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Website chuẩn SEO onepage</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bảo mật SSL: miễn phí</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao bộ code</li>
                                            <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Hoàn trả 200% giá trị hợp đồng khi không đáp ứng đúng yêu cầu như đã cam kết</li>
                                            {/*<li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>30%</span> khi đăng ký gói <a
                                                href="thiet-ke-logo">thiết kế logo</a></li>*/}
                                            <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <div className="benefit">
                        <div className="item">
                            <LazyImage
                                placeHolder={PlaceHolder}
                                src="https://api.takecare.vn/takecare/files/2021/03/MOCKUP-WEB.png"
                                width={`100%`}
                                height={`auto`}
                                effect={"opacity"}
                                alt="Lợi ích khi sử dụng dịch vụ của Takecare Agency"
                            />
                        </div>

                        <div className="item">
                            <div className="in">
                                <div className="title">
                                    <h2>Lợi ích khi thiết kế web tại <span>Takecare Agency</span></h2>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>

                                <ul className="list_">
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Thiết kế độc quyền - Giao diện đẹp và sang trọng đúng đẳng cấp nhận diện thương hiệu</li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Tốc độ tải trang nhanh dưới 3s</li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Website chuẩn Seo Onpage</li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Dễ sử dụng, tuỳ biến linh hoạt</li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Tương thích đa nền tảng</li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Đáp ứng mọi yêu cầu từ chức năng đơn giản cho đến phức tạp</li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i> Hỗ trợ 24/7 sau bàn giao</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>

                <LazyLoadComponent>
                    <div className="kho_">
                        <div className="container">
                            <div className="title center">
                                <h2>Kho giao diện đa dạng</h2>
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                            <div className="list_project list_project_home">
                                <div className="in">
                                    {this.state.webs_home.map((web, key) => (
                                        <div className="item" key={key}>
                                            <a href={'/kho-giao-dien/'+web.link}>
                                                <div className="img">
                                                    <img src={web.image} alt={web.title}/>
                                                </div>
                                                <h3><span>{web.title}</span></h3>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                                <div className="blog">
                                    <div className="btn">
                                        <Link to="/kho-giao-dien"><i className="fa fa-plus" aria-hidden="true"></i> Xem thêm</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
