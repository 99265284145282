import React, {Component} from 'react';
import LazyImage from "../../../Base/Lazy";
import PlaceHolder from "../../../../images/place-holder.png";
import { Link } from "react-router-dom";

export default class BlogRelated extends Component{
    render() {
        const slug = '/tin-tuc/'+this.props.slug;
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="item">
                <Link to={slug} onClick={LoadPage}>
                    <div className="insider">
                        <div className="img">
                            <LazyImage
                                placeHolder={PlaceHolder}
                                src={this.props.img}
                                width={`100%`}
                                height={`auto`}
                                effect={"opacity"}
                                alt={this.props.title}
                            />
                        </div>
                        <div className="date">
                            <span>{this.props.moth_day}</span>
                            <span>{this.props.year}</span>
                        </div>
                        <div className="content">
                            <h3>{this.props.title}</h3>
                            <div className="cate">
                                <i className="fa fa-tags" aria-hidden="true"></i>
                                {this.props.cat}
                            </div>
                            <p>{this.props.content}</p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}
