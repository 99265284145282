import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Process from "../Home/Process_";
import Why from "../Home/Why";
import axios from "axios";
import {Link} from "react-router-dom";
import Parser from "html-react-parser";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyImage from "../../Base/Lazy";
import PlaceHolder from "../../../images/place-holder.png";
import ImageAside from "../../../images/aside.png";
import ContactHome from "../Home/ContactHome";
import FromSideBar from "../../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Partners from "../Home/Partners";
import Logo from "../../../images/logo.png";

export default class SingleBranding extends Component{
    constructor(props) {
        super(props);
        this.state = {
            terms:  [],
            designs:  [],
            related: []
        }
    }
    componentDidMount() {
        axios.get(`https://api.takecare.vn/single-branding/?slug=`+this.props.match.params.slug)
            .then(res => {
                const designs = res.data;
                this.setState({ designs: designs });
                if(designs.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/related-branding/?slug=`+this.props.match.params.slug)
            .then(res => {
                const webs = res.data;
                this.setState({ related: webs });
                if(webs.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
    }

    render() {
        SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

        const img = 'https://webranding.vn/wp-content/uploads/2020/06/csd-02.jpg';

        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_project">
                {this.state.designs.map((design, key) => (
                    <Helmet key={key}>
                        <title>{design.title}</title>
                        <meta name="description" content={design.description} />
                        <meta name="keywords" content={design.tags} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={design.title} />
                        <meta property="og:description" content={design.description} />
                        <meta property="og:image" content={Logo} />
                    </Helmet>
                ))}

                {this.state.designs.map((design, key) => (
                <div className="slider_branding" key={key}>
                    <Swiper
                        loop={true}
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        navigation={{ clickable: true }}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 1,
                            },
                            1000: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {design.list.map((gallery, key) => (
                        <SwiperSlide key={key}>
                            <LazyImage
                                placeHolder={PlaceHolder}
                                src={gallery.image}
                                width={`100%`}
                                height={`auto`}
                                effect={"opacity"}
                                alt={design.title}
                            />
                        </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                ))}
                <div className="container">
                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                {this.state.designs.map((design, key) => (
                                <div className="content_single_blog" key={key}>
                                    <h1>{design.title}</h1>
                                    <div className="big">
                                        {Parser(design.content)}
                                    </div>
                                </div>
                                ))}

                                <LazyLoadComponent>
                                    <div className="title">
                                        <h2 className="title_ralated">Thương hiệu khác</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>

                                    <div className="list_brand list_brand_related">
                                        {this.state.related.map((design, key) => (
                                        <div className="item" key={key}>
                                            <Link to={'/branding/'+design.link} onClick={LoadPage}>
                                                <LazyImage
                                                    placeHolder={PlaceHolder}
                                                    src={design.image}
                                                    width={`100%`}
                                                    height={`auto`}
                                                    effect={"opacity"}
                                                    alt={design.title}
                                                />
                                                <h3><span>{design.title} <i className="fa fa-eye" aria-hidden="true"></i></span></h3>
                                            </Link>
                                        </div>
                                        ))}
                                    </div>
                                </LazyLoadComponent>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        )
    }
}
