const helpers = {
    PopupContact_ : function(){
        let elm = document.querySelector('.popup_form');
        elm.classList.add('ac');
    },
    ClosePopupContact_: function (){
        let elm = document.querySelector('.popup_form');
        elm.classList.remove('ac');
    },
    ToggleMenu_: function (){
        let elm = document.querySelector('.toggle_menu');
        let elm_menu = document.querySelector('.menu_toggle');
        //elm.classList.toggle('ac');
        elm_menu.classList.toggle('active');
    },
}

export default helpers;

