import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import facebook1 from "../../images/quang-cao-facebook.jpg";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class InfoSecurity extends Component{
    render() {
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Chính sách bảo mật và quyền riêng tư</title>
                    <meta name="description" content="Chính sách bảo mật và quyền riêng tư được công ty công khai minh bạch với cá nhân hoặc tổ chức khi sử dụng sản phẩm và dịch vụ của Takecare Agency" />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Chính sách bảo mật và quyền riêng tư" />
                    <meta property="og:description" content="Chính sách bảo mật và quyền riêng tư được công ty công khai minh bạch với cá nhân hoặc tổ chức khi sử dụng sản phẩm và dịch vụ của Takecare Agency" />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Chính sách bảo mật và quyền riêng tư</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Chính sách bảo mật và quyền riêng tư</h1>
                                    <div className="big">
                                        <h3>1. Mục đích thu thập thông tin cá nhân</h3>
                                        <p>Mục đích của việc thu thập thông tin khách hàng nhằm liên quan đến các vấn đề như:</p>
                                        <ul className="ul_big">
                                            <li>– Hỗ trợ khách hàng: tư vấn các dịch vụ của chúng tôi.</li>
                                            <li>– Cung cấp thông tin các dịch vụ và hỗ trợ theo yêu cầu của khách hàng.</li>
                                            <li>– Gửi thông báo các chương trình, dịch vụ mới nhất của chúng tôi.</li>
                                            <li>– Giải quyết vấn đề phát sinh khi sử dụng dịch vụ.</li>
                                            <li>– Ngăn chặn các hoạt động phạm pháp.</li>
                                            <li>– Đo lường và cải thiện các dịch vụ của chúng tôi.</li>
                                        </ul>
                                        <h3>2. Phạm vi thu thập thông tin</h3>
                                        <p>Chúng tôi thu thập thông tin cá nhân của khách hàng khi:</p>
                                        <ul className="ul_big">
                                            <li>– Khách hàng trực tiếp cung cấp: Khách hàng trực tiếp cung cấp bao gồm: họ tên, địa chỉ email, số điện thoại, địa chỉ.</li>
                                            <li>– Khách hàng tương tác với chúng tôi: Chúng tôi sử dụng cookies và công nghệ theo dấu khác để thu thập một số thông tin khi khách hàng tương tác trên website <strong>https://takecare.vn/</strong>.</li>
                                            <li>– Từ những nguồn hợp pháp khác: Chúng tôi thu thập thông tin khách hàng từ các nguồn hợp pháp khác.</li>
                                        </ul>
                                        <h3>3. Thời gian lưu trữ thông tin</h3>
                                        <p><strong>CÔNG TY CỔ PHẦN ĐẦU TƯ VÀ PHÁT TRIỂN TỦNG KHANG</strong> sẽ lưu trữ các thông tin cá nhân do khách hàng cung cấp trên các hệ thống nội bộ của chúng tôi trong quá trình cung cấp dịch vụ cho
                                            khách hàng hoặc khi khách hàng có yêu cầu hủy thông tin đã cấp.</p>
                                        <h3>4. Những người hoặc tổ chức có thể được tiếp cận với thông tin đó</h3>
                                        <ul className="ul_big">
                                            <li>– Các đối tác là bên cung cấp dịch vụ cho chúng tôi liên quan đến thực hiện đơn hàng và chỉ giới hạn trong phạm vi thông tin cần thiết cũng như áp
                                                dụng các quy định đảm bảo an ninh, bảo mật các thông tin cá nhân.</li>
                                            <li>– Chúng tôi sử dụng dịch vụ từ một nhà cung cấp dịch vụ là bên thứ ba để thực hiện một số hoạt động liên quan đến website <strong>https://takecare.vn/</strong>. Khi đó, bên thứ ba có thể truy cập hoặc xử lý các thông tin cá nhân trong quá trình cung cấp các dịch vụ đó. Chúng tôi yêu cầu các bên thứ ba này tuân thủ mọi luật lệ về bảo vệ thông tin cá nhân liên quan và các yêu cầu về an ninh liên quan đến thông tin cá nhân.</li>
                                            <li>– Các chương trình có tính liên kết, đồng thực hiện, thuê ngoài cho các mục đích được nêu tại Mục 1 và luôn áp dụng các yêu cầu bảo mật thông tin cá nhân.</li>
                                            <li>– Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá nhân nếu điều đó do luật pháp yêu cầu và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân thủ các quy trình pháp lý.</li>
                                            <li>– Chuyển giao kinh doanh (nếu có): trong trường hợp sáp nhập, hợp nhất toàn bộ hoặc một phần với công ty khác, người mua sẽ có quyền truy cập thông tin được chúng tôi lưu trữ, duy trì trong đó bao gồm cả thông tin cá nhân.</li>
                                        </ul>
                                        <h3>5. Địa chỉ của đơn vị thu thập và quản lý thông tin</h3>
                                        <ul className="ul_big">
                                            <li>– Tên doanh nghiệp: <strong>CÔNG TY CỔ PHẦN ĐẦU TƯ VÀ PHÁT TRIỂN TỦNG KHANG</strong></li>
                                            <li>– Thành lập và hoạt động theo Giấy chứng nhận đăng ký doanh nghiệp với mã số thuế: <strong>0316540234</strong> do Sở Kế hoạch & Đầu tư TP Hồ Chí Minh cấp lần đầu ngày 14/10/2020. Đăng ký thay đổi lần thứ 1 ngày 01/03/2021.</li>
                                            <li>– Trụ sở chính: 59B Đường 22, Phường Phước Long B, Thành phố Thủ Đức, Thành phố Hồ Chí Minh</li>
                                        </ul>
                                        <h3>6. Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu:</h3>
                                        <ul className="ul_big">
                                            <li>Nếu quý khách có bất cứ về yêu cầu nào về việc tiếp cận và chỉnh sửa thông tin cá nhân đã cung cấp, quý khách có thể:</li>
                                            <li>– Gọi điện trực tiếp về số điện thoại: <strong>0963.339.934</strong></li>
                                            <li>– Gửi mail: <strong>info@takecare.vn</strong></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="ite">
                                <aside>
                                    <div className="widget">
                                        <div className="title">
                                            <h2>Tư vấn dịch vụ</h2>
                                            <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>

                                        <div className="contact_form">
                                            <LazyImage
                                                placeHolder={PlaceHolder}
                                                src={ImageAside}
                                                width={`100%`}
                                                height={`auto`}
                                                effect={"opacity"}
                                                alt="Liên hệ"
                                            />
                                            <ul>
                                                <li>
                                                    <input type="text" className="name" placeholder="Họ Và Tên" />
                                                </li>
                                                <li>
                                                    <input type="text" className="email" placeholder="Email" />
                                                </li>
                                                <li>
                                                    <input type="text" className="tel" placeholder="Số điện thoại" />
                                                </li>
                                                <li>
                                                    <textarea className="khac" placeholder="Ghi chú thêm"></textarea>
                                                </li>
                                                <li>
                                                    <button type="button" className="send">Tư vấn ngay<i
                                                        className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
