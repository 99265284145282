import React, { Component } from 'react';
import Logo from '../../images/logo.png';
import { Link } from "react-router-dom";
import helpers from "../Event/helpers";
import axios from "axios";
import PlaceHolder from "../../images/place-holder.png";
import LazyImage from "./Lazy";

export default class Header extends Component{
    constructor(props, context) {
        super(props, context);
        this.state = {
            phone1: '',
            phone2: '',
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/phone-api/`)
            .then(res => {
                const phone_list = res.data;
                this.setState({ phone1: phone_list[0].phone1 });
                this.setState({ phone2: phone_list[0].phone2 });
            })
            .catch(error => console.log(error));
    }

    render() {
        document.addEventListener('scroll', function(e) {
            var navbar = document.querySelector(".header_main");
            var sticky = navbar.offsetTop;

            if (window.pageYOffset > sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        });

        if (document.body.clientWidth >= 1025) {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.async=true;
                js.src = "//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.6&appId=";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }

        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }

        function handleToggleMenuMobile(event)  {
            event.target.parentNode.nextSibling.classList.toggle('active');
            event.target.classList.toggle('active');
        }

        function handleCloseMenuMobile(event){
            event.target.parentNode.parentNode.classList.remove('active');
        }

        function handleShowMenuMobile(event){
            helpers.ToggleMenu_();
        }

        function handleClickMenuMobile(event){
            event.target.parentNode.parentNode.parentNode.parentNode.classList.remove('active');
        }

        function handleClickMenuMobile2(event){
            event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove('active');
        }

        function handleClickMenuMobile3(event){
            event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove('active');
        }
        return (
            <div>
                <div className="header">
                    <div className="top_head">
                        <div className="container">
                            <div className="left">
                                <ul>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i> {this.state.phone2} {' - '+this.state.phone1}</li>
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i> info@takecare.vn</li>
                                </ul>
                            </div>

                            <div className="right">
                                <ul>
                                    <li><a href="https://www.facebook.com/Agency.Takecare"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
                                    <li><a href="https://www.youtube.com/"><i className="fa fa-youtube-play" aria-hidden="true"></i> </a></li>
                                    <li><a href="https://gmail.com/"><i className="fa fa-google-plus" aria-hidden="true"></i> </a></li>
                                    <li><a href="https://www.pinterest.com/"><i className="fa fa-pinterest-p" aria-hidden="true"></i> </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="header_main">
                        <div className="container">
                            <div className="logo">
                                <Link to="/">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={Logo}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt="Takecare Agency"
                                    />
                                </Link>
                            </div>
                            <div className="menu">
                                <div className="menu_toggle">
                                    <div className="close" onClick={handleCloseMenuMobile}><i className="fa fa-times-circle" aria-hidden="true"></i></div>
                                    <img src={Logo} alt="Takecare Agency"/>
                                    <ul>
                                        <li>
                                            <span>
                                                <Link to="/ve-chung-toi" onClick={handleClickMenuMobile}>Về chúng tôi</Link>
                                                {/*<i className="fa fa-caret-right" onClick={handleToggleMenuMobile} aria-hidden="true"></i>*/}
                                            </span>
                                            {/*<ul>
                                                <li>
                                                    <span><Link to="/ve-chung-toi" onClick={handleClickMenuMobile2}>Giới thiệu</Link></span>
                                                </li>
                                                <li>
                                                    <span><Link to="/ve-chung-toi" onClick={handleClickMenuMobile2}>Đối tác</Link></span>
                                                </li>
                                                <li>
                                                    <span><Link to="/ve-chung-toi" onClick={handleClickMenuMobile2}>Tuyển dụng</Link></span>
                                                </li>
                                            </ul>*/}
                                        </li>
                                        <li>
                                            <span>
                                                <Link to="#" onClick={handleClickMenuMobile}>Dịch vụ</Link>
                                                <i className="fa fa-caret-right" onClick={handleToggleMenuMobile} aria-hidden="true"></i>
                                            </span>
                                            <ul>
                                                <li>
                                                    <span>
                                                        <Link to="/dich-vu/thiet-ke-logo" onClick={handleClickMenuMobile2}>Thiết kế logo</Link>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        <Link to="/dich-vu/thiet-ke-web" onClick={handleClickMenuMobile2}>Thiết kế website</Link>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        <Link to="#" onClick={handleClickMenuMobile2}>Marketing online</Link>
                                                        <i className="fa fa-caret-right" onClick={handleToggleMenuMobile} aria-hidden="true"></i>
                                                    </span>
                                                    <ul>
                                                        <li>
                                                            <Link to="/dich-vu/quang-cao-google" onClick={handleClickMenuMobile3}>Quảng cáo Google</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dich-vu/quang-cao-facebook" onClick={handleClickMenuMobile3}>Quảng cáo Facebook</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dich-vu/seo-website" onClick={handleClickMenuMobile3}>Seo Website</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dich-vu/cham-soc-website" onClick={handleClickMenuMobile3}>Chăm sóc website</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dich-vu/bien-soan-noi-dung" onClick={handleClickMenuMobile3}>Biên soạn nội dung</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>
                                                <Link to="#" onClick={handleClickMenuMobile}>Dự án</Link>
                                                <i className="fa fa-caret-right" onClick={handleToggleMenuMobile} aria-hidden="true"></i>
                                            </span>
                                            <ul>
                                                <li>
                                                    <span><Link to="/kho-giao-dien" onClick={handleClickMenuMobile2}>Kho giao diện</Link></span>
                                                </li>
                                                <li>
                                                    <span><Link to="/thuong-hieu" onClick={handleClickMenuMobile2}>Thương hiệu</Link></span>
                                                </li>
                                            </ul>
                                        </li>
                                        {/*<li>
                                            <span>
                                                <Link to="/khuyen-mai" onClick={handleClickMenuMobile}>Khuyến mãi</Link>
                                            </span>
                                        </li>*/}
                                        <li>
                                            <span><Link to="/tin-tuc" onClick={handleClickMenuMobile}>Tin tức</Link></span>
                                        </li>
                                        {/*<li>
                                        <Link to="/ho-tro">Hỗ trợ</Link>
                                    </li>*/}
                                        <li>
                                            <span><Link to="/lien-he" onClick={handleClickMenuMobile}>Liên hệ</Link></span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="toggle_menu" onClick={handleShowMenuMobile}>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                </div>
                                <a href="/" className="btn popup_contact" onClick={ PopupContact }><i className="fa fa-envelope-open" aria-hidden="true"></i> Tư vấn</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="fb-root"></div>
                <div className="fb-customerchat"
                     attribution="page_inbox"
                     page_id="1311593955629782">
                </div>
            </div>
        );
    }
}

