import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Banking extends Component{
    render() {
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Hình thức thanh toán</title>
                    <meta name="description" content="Hình thức thanh toán được công ty công khai minh bạch với cá nhân hoặc tổ chức khi sử dụng sản phẩm và dịch vụ của Takecare Agency" />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Hình thức thanh toán" />
                    <meta property="og:description" content="Hình thức thanh toán được công ty công khai minh bạch với cá nhân hoặc tổ chức khi sử dụng sản phẩm và dịch vụ của Takecare Agency" />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Hình thức thanh toán</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Hình thức thanh toán</h1>
                                    <div className="big">
                                        <p><strong>Takecare Agency</strong> cung cấp cho khách hàng 2 hình thức thanh toán khi sử dụng dịch vụ :</p>
                                        <h2>1. Thanh toán tiền mặt</h2>
                                        <p>- Khách hàng vui lòng thanh toán bằng hình thức trả tiền mặt tại :</p>
                                        <p>Tại: 59B Đường 22, Phường Phước Long B, Thành phố Thủ Đức, Thành phố Hồ Chí Minh.</p>
                                        <h2>2. Thanh toán chuyển khoản ngân hàng</h2>
                                        <div className="bank_list">
                                            <ul className="ul_big">
                                                <li><b>- Quý khách vui lòng chuyển khoản theo thông tin sau: </b></li>
                                                <li>+ Tên ngân hàng: Ngân hàng á châu ACB</li>
                                                <li>+ Chủ tài khoản: Công ty cổ phần đầu tư & phát triển Tùng Khang</li>
                                                <li>+ Số tài khoản: <strong>386383838</strong></li>
                                                <li>+ Chi nhánh: Hồ Chí Minh</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ite">
                                <aside>
                                    <div className="widget">
                                        <div className="title">
                                            <h2>Tư vấn dịch vụ</h2>
                                            <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>

                                        <div className="contact_form">
                                            <LazyImage
                                                placeHolder={PlaceHolder}
                                                src={ImageAside}
                                                width={`100%`}
                                                height={`auto`}
                                                effect={"opacity"}
                                                alt="Liên hệ"
                                            />
                                            <ul>
                                                <li>
                                                    <input type="text" className="name" placeholder="Họ Và Tên" />
                                                </li>
                                                <li>
                                                    <input type="text" className="email" placeholder="Email" />
                                                </li>
                                                <li>
                                                    <input type="text" className="tel" placeholder="Số điện thoại" />
                                                </li>
                                                <li>
                                                    <textarea className="khac" placeholder="Ghi chú thêm"></textarea>
                                                </li>
                                                <li>
                                                    <button type="button" className="send">Tư vấn ngay<i
                                                        className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
