import React, { Component } from 'react';
import Header from "./components/Base/Header";
import Footer from "./components/Base/Footer";
import Home from "./components/Page/Home";
import About from "./components/Page/About";
import BlogList from "./components/Page/BlogList";
import SingleBlog from "./components/Page/Single/SingleBlog";
import PopupForm from "./components/Base/PopupForm";
import Contact from "./components/Page/Contact";
import Page404 from "./components/Page/Page404";
import Project from "./components/Page/Project";
import SingleProject from "./components/Page/Single/SingleProject";
import Basic from "./components/Page/websiteBasic";
import Pro from "./components/Page/websitePro";
import Care from "./components/Page/Care";
import Google from "./components/Page/Google";
import Facebook from "./components/Page/Facebook";
import PagiBlog from "./components/Page/pagi/PagiBlog";
import BogListSale from "./components/Page/BogListSale";
import PagiBlogSale from "./components/Page/pagi/PagiBlogSale";
import Seo from "./components/Page/Seo";
import Content from "./components/Page/Content";
import ProjectCate from "./components/Page/ProjectCate";
import PagiProject from "./components/Page/pagi/PagiProject";
import PagiProjectCate from "./components/Page/pagi/PagiProjectCate";
import Designer from "./components/Page/Designer";
import Branding from "./components/Page/Branding";
import SingleBranding from "./components/Page/Single/singleBranding";
import BrandingCate from "./components/Page/BrandingCate";
import PagiBranding from "./components/Page/pagi/PagiBranding";
import PagiBrandingCate from "./components/Page/pagi/PagiBrandingCate";
import InfoSecurity from "./components/Page/InfoSecurity";
import Gua from "./components/Page/Gua";
import Banking from "./components/Page/Banking";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from "react-router-dom";


export default class App extends Component{
    render() {
        return (
            <BrowserRouter>
                <div className="app">
                    <LazyLoadComponent>
                        <Header />
                    </LazyLoadComponent>

                    <div className="main">
                        <Switch>
                            <Route exact path="/404">
                                <Page404 />
                            </Route>
                            <Route exact path="/">
                                <Home />
                            </Route>

                            <Route path="/ve-chung-toi/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/ve-chung-toi">
                                <About />
                            </Route>

                            <Route path="/lien-he/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/lien-he">
                                <Contact />
                            </Route>

                            <Route path="/chinh-sach-bao-mat/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/chinh-sach-bao-mat">
                                <InfoSecurity />
                            </Route>

                            <Route path="/chinh-sach-bao-hanh/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/chinh-sach-bao-hanh">
                                <Gua />
                            </Route>

                            <Route path="/hinh-thuc-thanh-toan/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/hinh-thuc-thanh-toan">
                                <Banking />
                            </Route>

                            {/*<Route path="/dich-vu/thiet-ke-website-gia-re/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/thiet-ke-website-gia-re">
                                <Basic />
                            </Route>*/}

                            <Route path="/dich-vu/thiet-ke-web/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/thiet-ke-web">
                                <Pro />
                            </Route>

                            <Route path="/dich-vu/cham-soc-website/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/cham-soc-website">
                                <Care />
                            </Route>

                            <Route path="/dich-vu/quang-cao-google/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/quang-cao-google">
                                <Google />
                            </Route>

                            <Route path="/dich-vu/quang-cao-facebook/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/quang-cao-facebook">
                                <Facebook />
                            </Route>

                            <Route path="/dich-vu/seo-website/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/seo-website">
                                <Seo />
                            </Route>

                            <Route path="/dich-vu/bien-soan-noi-dung/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/bien-soan-noi-dung">
                                <Content />
                            </Route>

                            <Route path="/dich-vu/thiet-ke-logo/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/dich-vu/thiet-ke-logo" component={Designer}/>

                            <Route path="/tin-tuc/page/:id" component={PagiBlog}/>

                            <Route path="/tin-tuc/:slug/*">
                                <Page404 />
                            </Route>

                            <Route path="/tin-tuc/:slug" component={SingleBlog}/>

                            <Route path="/khuyen-mai/:slug" component={PagiBlogSale}/>

                            <Route path="/tin-tuc" component={BlogList}/>

                            <Route path="/khuyen-mai" component={BogListSale}/>

                            <Route path="/branding/:slug" component={SingleBranding}/>

                            <Route path="/thuong-hieu/page/:id" component={PagiBranding}/>

                            <Route path="/thuong-hieu/:slug/page/:id" component={PagiBrandingCate}/>

                            <Route path="/thuong-hieu/:slug" component={BrandingCate}/>

                            <Route path="/thuong-hieu" component={Branding}/>

                            {/*<Route path="/du-an/:slug">
                                <SingleProject />
                            </Route>*/}

                            <Route path="/kho-giao-dien/page/:id" component={PagiProject}/>

                            <Route path="/:slug/page/:id" component={PagiProjectCate}/>

                            <Route path="/kho-giao-dien/:slug/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/kho-giao-dien/:slug" component={SingleProject}/>

                            <Route path="/kho-giao-dien">
                                <Project />
                            </Route>

                            <Route path="/:slug/*">
                                <Redirect to="/404" />
                            </Route>
                            <Route path="/:slug" component={ProjectCate}/>

                            <Route>
                                <Page404 />
                            </Route>
                        </Switch>
                    </div>
                    <PopupForm />
                    <LazyLoadComponent>
                        <Footer />
                    </LazyLoadComponent>
                </div>
            </BrowserRouter>
        );
    }
}
