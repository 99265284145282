import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import helpers from "../Event/helpers";
import Image from "../../images/img1.jpg";
import axios from "axios";
import ItemDesign from "./Design/ItemDesign";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import {Link} from "react-router-dom";
import Logo from "../../images/logo.png";
import about2 from "../../images/about2.jpg";
import PlaceHolder from "../../images/place-holder.png";
import LazyImage from "../Base/Lazy";

export default class Designer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            designs:  [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/list-branding/`)
            .then(res => {
                const designs = res.data;
                this.setState({ designs: designs });
            })
            .catch(error => console.log(error));

        const publicIp = require('public-ip');

        (async () => {
            const data = new FormData();
            data.append('ip', await publicIp.v4());

            const getUA = () => {
                let device = "Unknown";
                const ua = {
                    "Generic Linux": /Linux/i,
                    "Android": /Android/i,
                    "BlackBerry": /BlackBerry/i,
                    "Bluebird": /EF500/i,
                    "Chrome OS": /CrOS/i,
                    "Datalogic": /DL-AXIS/i,
                    "Honeywell": /CT50/i,
                    "iPad": /iPad/i,
                    "iPhone": /iPhone/i,
                    "iPod": /iPod/i,
                    "macOS": /Macintosh/i,
                    "Windows": /IEMobile|Windows/i,
                    "Zebra": /TC70|TC55/i,
                }
                Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
                return device;
            }

            data.append('device', getUA());
            axios.post('https://api.takecare.vn/get-ip/', data)
                .then(response => {
                    //console.log(response)
                    //this.filter = response.data
                })
                .catch(e => {
                    //console.log(e)
                });
        })();
    }

    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_blog page_basic page_th">
                <Helmet>
                    <title>Thiết kế logo</title>
                    <meta name="description" content="Thiết kế logo đẹp, hiện đại và chuyên nghiệp giúp cho khách hàng ấn tượng và đặt trọn niềm tin khi lựa chọn doanh nghiệp của bạn. Hãy để chúng tôi tạo ra nó giúp bạn." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Thiết kế logo" />
                    <meta property="og:description" content="Dịch vụ thiết kế logo chuyên nghiệp giúp cho khách hàng ấn tượng và đặt trọn niềm tin khi lựa chọn doanh nghiệp của bạn. Hãy để chúng tôi tạo ra nó giúp bạn." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="banner_page first_banner_"  style={{ background: 'url(' + Image + ')' }}>
                    <div className="container">
                        <div className="first_banner">
                            <h1>Thiết kế logo</h1>
                            <p className="des_banner">Thiết kế logo đẹp, hiện đại và chuyên nghiệp giúp cho khách hàng ấn tượng và đặt trọn niềm tin <br/> khi lựa chọn doanh nghiệp của bạn.</p>
                        </div>
                    </div>
                </div>

                <div className="about_logo">
                    <div className="container">
                        <div className="in">
                            <div className="item">
                                <LazyImage
                                    placeHolder={PlaceHolder}
                                    src={about2}
                                    width={`100%`}
                                    height={`auto`}
                                    effect={"opacity"}
                                    alt="Thiết kế logo"
                                />
                            </div>

                            <div className="item">
                                <div className="title">
                                    <h2>Logo là bộ mặt thương hiệu</h2>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                                <p>Các bạn cũng biết, logo là bộ mặt thương hiệu. Nó là đại diện và gắn liền xuyên suốt quá trình
                                    hình thành và phát triển của một thương hiệu.</p>
                                <p>Logo có đẹp - tinh tế - sang trọng thì luôn thu hút
                                    mọi người chú ý đến. Và điều quan trọng là cái nhìn đầu tiên bao giờ cũng phải ấn tượng thì khách hàng
                                    mới có thể đặt trọn niềm tin vào sản phẩm, dịch vụ của thương hiệu.</p>
                                <p>Chính vì thế để tìm kiếm được một đơn vị uy tín là cực kỳ quan trọng. Hãy
                                    để <strong>Takecare Agency</strong> giúp bạn làm việc đó.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="list_create_logo">
                    <div className="container">
                        <div className="branding_page">
                            <div className="title center">
                                <h2>Bảng giá thiết kế logo</h2>
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                            <div className="box">
                                <div className="item">
                                    <h3>Gói A</h3>
                                    <ul>
                                        <li><del>490.000 VNĐ</del> <span style={{fontSize: '14px',display: 'block',}}>Dừng kinh doanh</span></li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế 3 mẫu có sẵn</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế namecard</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Phòng bì thư</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế kẹp hồ sơ</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Áo đồng phục</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế thẻ nhân viên</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Biển hiệu</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Tờ rơi</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế tiêu đề thư</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Backdrop</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Hồ sơ năng lực</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Chỉnh sửa 0 lần</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao File gốc</li>
                                        <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                    </ul>
                                </div>
                                <div className="item active">
                                    <h3>Gói B</h3>
                                    <ul>
                                        <li>2.490.000 VNĐ</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế 3 mẫu</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế namecard</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Phòng bì thư</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế kẹp hồ sơ</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Áo đồng phục</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế thẻ nhân viên</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Biển hiệu</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Tờ rơi</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế tiêu đề thư</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Backdrop</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Hồ sơ năng lực</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Chỉnh sửa 3 lần</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao File gốc</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>10%</span> khi đăng ký gói <a
                                            href="thiet-ke-web">thiết kế website</a></li>
                                        <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <h3>Gói C</h3>
                                    <ul>
                                        <li>5.990.000 VNĐ</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế 3 mẫu</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế namecard</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Phòng bì thư</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế kẹp hồ sơ</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Áo đồng phục</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế thẻ nhân viên</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Biển hiệu</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế Tờ rơi</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế tiêu đề thư</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Backdrop</li>
                                        <li><i className="fa fa-minus-circle" aria-hidden="true"></i> Thiết kế Hồ sơ năng lực</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Chỉnh sửa 5 lần</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao File gốc</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>10%</span> khi đăng ký gói <a
                                            href="thiet-ke-web">thiết kế website</a></li>
                                        <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <h3>Gói D</h3>
                                    <ul>
                                        <li>9.990.000 VNĐ</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế 4 mẫu</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế namecard</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Phòng bì thư</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế kẹp hồ sơ</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Áo đồng phục</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế thẻ nhân viên</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế Biển hiệu</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế Tờ rơi</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế tiêu đề thư</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế Backdrop</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Thiết kế Hồ sơ năng lực</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Chỉnh sửa không giới hạn</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Bàn giao File gốc</li>
                                        <li><i className="fa fa-plus-circle" aria-hidden="true"></i> Giảm <span>10%</span> khi đăng ký gói <a
                                            href="thiet-ke-web">thiết kế website</a></li>
                                        <li className="btn"><a className="popup_contact_custom" onClick={PopupContact}>Đăng ký ngay</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="list_brand">
                    {this.state.designs.map((design, key) => (
                        <ItemDesign
                            key={key}
                            title={design.title}
                            slug={design.link}
                            image={design.image}
                        />
                    ))}
                </div>

                <div className="blog" style={{paddingTop:'20px',marginBottom:'50px'}}>
                    <div className="btn">
                        <Link  target='_blank' to="/thuong-hieu"><i className="fa fa-plus" aria-hidden="true"></i> Xem thêm</Link>
                    </div>
                </div>

                {/*<div className="container branding_table_price">
                    <div className="title center">
                        <h2>Bảng giá nhận diện thương hiệu</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className="pricing">
                        <div className="columns">
                            <ul className="price">
                                <li className="header">Gói A</li>
                                <li>Thiết kế logo chuyên nghiệp <div>(2 mẫu chọn 1)</div></li>
                                <li>Thiết kế Namecard</li>
                                <li>Phong bì thư</li>
                                <li>Thiết kế thẻ nhân viên</li>
                                <li>Thiết kế kẹp</li>
                                <li>Thiết kế Tiêu đề thư</li>
                                <li>Thời gian bàn giao 1 - 5 ngày</li>
                                <li>Bàn giao đầy đủ file thiết kế</li>
                                <li>
                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                </li>
                            </ul>
                        </div>

                        <div className="columns">
                            <ul className="price">
                                <li className="header">Gói B</li>
                                <li>Thiết kế logo chuyên nghiệp <div>(3 mẫu chọn 1)</div></li>
                                <li>Thiết kế Namecard</li>
                                <li>Phong bì thư</li>
                                <li>Thiết kế đồng phục</li>
                                <li>Thiết kế thẻ nhân viên</li>
                                <li>Thiết kế kẹp</li>
                                <li>Thiết kế Tiêu đề thư</li>
                                <li>Thời gian bàn giao 5 - 10 ngày</li>
                                <li>Bàn giao đầy đủ file thiết kế</li>
                                <li>
                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                </li>
                            </ul>
                        </div>

                        <div className="columns">
                            <ul className="price">
                                <li className="header">Gói C</li>
                                <li>Thiết kế logo chuyên nghiệp <div>(3 mẫu chọn 1)</div></li>
                                <li>Thiết kế Namecard</li>
                                <li>Phong bì thư</li>
                                <li>Thiết kế đồng phục</li>
                                <li>Thiết kế thẻ nhân viên</li>
                                <li>Thiết kế kẹp</li>
                                <li>Thiết kế Tiêu đề thư</li>
                                <li>Thiết kế Biển hiệu công ty</li>
                                <li>Thiết kế Profile công ty <div>(8 trang)</div></li>
                                <li>Thời gian bàn giao 10 - 15 ngày</li>
                                <li>Bàn giao đầy đủ file thiết kế</li>
                                <li>
                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                </li>
                            </ul>
                        </div>

                        <div className="columns">
                            <ul className="price">
                                <li className="header">Gói D</li>
                                <li>Thiết kế logo chuyên nghiệp <div>(3 mẫu chọn 1)</div></li>
                                <li>Thiết kế Namecard</li>
                                <li>Phong bì thư</li>
                                <li>Thiết kế kẹp</li>
                                <li>Thiết kế đồng phục</li>
                                <li>Thiết kế Tiêu đề thư</li>
                                <li>Thiết kế Biển hiệu công ty</li>
                                <li>Thiết kế Tờ rơi</li>
                                <li>Thiết kế Backdrop</li>
                                <li>Thiết kế Hồ sơ năng lực công ty <div>(12 trang)</div></li>
                                <li>Thời gian bàn giao 15 - 30 ngày</li>
                                <li>Bàn giao đầy đủ file thiết kế</li>
                                <li>
                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>*/}

                <div className="ck">
                    <div className="container">
                        <div className="title center">
                            <h2>Cam kết chất lượng</h2>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>

                        <div className="list_ck">
                            <div className="item">
                                <i className="fa fa-check-circle" aria-hidden="true"></i>
                                <h3>Đáp ứng tiến độ</h3>
                                <p><strong>Takecare Agency</strong> đảm bảo về chất lượng công việc, đáp ứng tiến độ thiết kế của khách hàng.</p>
                            </div>
                            <div className="item">
                                <i className="fa fa-check-circle" aria-hidden="true"></i>
                                <h3>Tư vấn miễn phí 100%</h3>
                                <p><strong>Takecare Agency</strong> sẽ giúp bạn giải đáp mọi thắc mắc liên quan đến xây dựng bộ nhận diện thương hiệu.</p>
                            </div>
                            <div className="item">
                                <i className="fa fa-check-circle" aria-hidden="true"></i>
                                <h3>Chỉnh sửa không giới hạn</h3>
                                <p><strong>Takecare Agency</strong> chỉnh sửa không giới hạn tới khi khách hàng hài lòng tới bản cuối cùng.</p>
                            </div>
                            <div className="item">
                                <i className="fa fa-check-circle" aria-hidden="true"></i>
                                <h3>Hỗ trợ khách hàng tận tâm</h3>
                                <p><strong>Takecare Agency</strong> đảm bảo tư vấn nhiệt tình "Trước, trong và sau" bàn giao.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="process_brading">
                    <div className="container">
                        <div className="title center">
                            <h2>Quy trình thiết kế</h2>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>

                        <div className="list_">
                            <div className="item">
                                <span>1</span>
                                <h3>Nghiên cứu và định hướng</h3>
                                <p><strong>Takecare Agency</strong> sẽ nghiên cứu thông tin doanh nghiệp, đối thủ trong ngành, để phác thảo định hướng triển khai dịch vụ thiết kế nhận diện hiệu quả.</p>
                            </div>
                            <div className="item">
                                <span>2</span>
                                <h3>Phác thảo ý tưởng và thiết kế</h3>
                                <p>Sau khi đã nghiên cứu và có định hướng dịch vụ, <strong>Takecare Agency</strong> sẽ lên ý tưởng thiết kế nhận diện thương hiệu phù hợp với yêu cầu của doanh nghiệp.</p>
                            </div>
                            <div className="item">
                                <span>3</span>
                                <h3>Thuyết trình ý tưởng</h3>
                                <p><strong>Takecare Agency</strong> luôn có những bản thuyết trình ý tưởng thiết kế cho từng mẫu thiết kế cung cấp, đảm bảo tính nhất quán với nhu cầu khách hàng.</p>
                            </div>
                            <div className="item">
                                <span>4</span>
                                <h3>Sản xuất, in ấn & Bàn Giao</h3>
                                <p><strong>Takecare Agency</strong> sẽ chọn kiểu dáng và chất liệu in ấn phù hợp với nhu cầu của doanh nghiệp, đảm bảo chất lượng và bàn giao sản phẩm cho khách hàng.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
