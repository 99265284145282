import React, {Component} from 'react';
import Image from "../../../images/tech.jpg";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Process from "../Home/Process_";
import Why from "../Home/Why";
import axios from "axios";
import {Link} from "react-router-dom";
import Parser from "html-react-parser";
import ContactHome from "../Home/ContactHome";
import helpers from "../../Event/helpers";
import PlaceHolder from "../../../images/place-holder.png";
import LazyImage from "../../Base/Lazy";
import {Helmet} from "react-helmet";
import Partners from "../Home/Partners";
import Logo from "../../../images/logo.png";

export default class SingleProject extends Component{
    constructor(props) {
        super(props);
        this.state = {
            terms:  [],
            webs:  [],
            related: [],
            cat: [],
            screen: 1,
            showDemo: false,
        }
        this.onClick1 = this.onClick1.bind(this);
        this.onClick2 = this.onClick2.bind(this);
        this.onClick3 = this.onClick3.bind(this);
        this.showDemo = this.showDemo.bind(this);
    }
    componentDidMount() {
        axios.get(`https://api.takecare.vn/category-website/`)
            .then(res => {
                const terms = res.data;
                this.setState({ terms: terms });
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/single-project/?slug=`+this.props.match.params.slug)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
                if(webs.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/related-project/?slug=`+this.props.match.params.slug)
            .then(res => {
                const webs = res.data;
                this.setState({ related: webs });
            })
            .catch(error => console.log(error));
    }

    onClick1(){
        this.setState({ screen : 1} );
    };

    onClick2(){
        this.setState({ screen : 2} );
    };

    onClick3(){
        this.setState({ screen : 3} );
    };

    showDemo(e){
        e.preventDefault();
        this.setState({ showDemo : ! this.state.showDemo} );
    };

    render() {
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_project">
                {this.state.webs.map((web, key) => (
                    <Helmet key={key}>
                        <title>{web.title}</title>
                        <meta name="description" content={web.description} />
                        <meta name="keywords" content={web.tags} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={web.title} />
                        <meta property="og:description" content={web.description} />
                        <meta property="og:image" content={Logo} />
                    </Helmet>
                ))}

                <div className="banner_page"  style={{ background: 'url(' + Image + ')' }}>
                    <div className="container">
                        {this.state.webs.map((web, key) => (
                        <div className="in" key={key}>
                            <h1>{web.title}</h1>
                            <div className="breadcrums">
                                <ul>
                                    <li><a href="/">Trang chủ</a></li>
                                    <li>/</li>
                                    <li><a href="/">Kho giao diện</a></li>
                                    <li>/</li>
                                    <li><span>{web.title}</span></li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>

                <div className="container">
                    <div className="main_project">
                        <div className="side">
                            <aside>
                                <div className="title">
                                    <h2>Danh mục website</h2>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>

                                <div className="list_cate_product">
                                    <ul>
                                        {this.state.terms.map((term, key) => (
                                            <li key={key} data-id={term.id}>
                                                <a href={"/"+term.slug}><i className={'fa '+term.icon}></i> {term.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        {this.state.webs.map((web, key) => (
                        <div className="right" key={key}>
                            <div className="list">
                                <div className="item">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={web.image}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt={web.title}
                                    />
                                </div>
                                <div className="item" key={key}>
                                    <div className="title">
                                        <h2>{web.title}</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>

                                    <p>{Parser(web.content)}</p>

                                    <ul>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Giao diện đẹp và sang trọng </li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tốc độ tải trang nhanh</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Website chuẩn Seo Onpage</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Dễ sử dụng, tuỳ biến linh hoạt</li>
                                        <li><i className="fa fa-check-circle" aria-hidden="true"></i> Tương thích đa nền tảng</li>
                                    </ul>
                                    <div className="btn">
                                        <a href="/" onClick={PopupContact}><i className="fa fa-shopping-cart" aria-hidden="true"></i> Liên hệ</a>
                                        <a rel="noreferrer" target='_blank' className="btn_single_web_mobile" href={web.url}><i className="fa fa-eye" aria-hidden="true"></i> Xem demo</a>
                                        <a rel="noreferrer" className="btn_single_web_pc" onClick={this.showDemo}><i className="fa fa-eye" aria-hidden="true"></i> Xem demo</a>
                                    </div>
                                </div>
                            </div>

                            <div className="related">
                                <div className="title">
                                    <h2>Xem giao diện khác</h2>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>

                                <div className="list_project">
                                    <div className="in">
                                        {this.state.related.map((web, key) => (
                                        <div className="item" key={key}>
                                            <Link to={'/kho-giao-dien/'+web.link} onClick={LoadPage}>
                                                <div className="img">
                                                    <LazyImage
                                                        placeHolder={PlaceHolder}
                                                        src={web.image}
                                                        width={`100%`}
                                                        height={`auto`}
                                                        effect={"opacity"}
                                                        alt={web.title}
                                                    />
                                                </div>
                                                <h3><span>{web.title}</span></h3>
                                            </Link>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.showDemo == true ? 'show_demo active' : 'show_demo'}>
                                <div className="in">
                                    <div className="close" onClick={this.showDemo}><i className="fa fa-times-circle" aria-hidden="true"></i></div>
                                    <div className="menu_slt">
                                        <ul>
                                            <li className={this.state.screen == 1 ? 'active' : ''} onClick={this.onClick1}><i className="fa fa-desktop" aria-hidden="true"></i></li>
                                            <li className={this.state.screen == 2 ? 'active' : ''} onClick={this.onClick2}><i className="fa fa-tablet" aria-hidden="true"></i></li>
                                            <li className={this.state.screen == 3 ? 'active' : ''} onClick={this.onClick3}><i className="fa fa-mobile" aria-hidden="true"></i></li>
                                        </ul>
                                    </div>
                                    <iframe src={this.state.screen == 1 ? web.url : this.state.screen == 2 ? web.url : web.url} frameBorder="0" className={this.state.screen == 1 ? 'pc': this.state.screen == 2 ? 'tablet' : 'mobile'}></iframe>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        )
    }
}
