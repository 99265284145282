import React, {Component} from 'react';
import Image from "../../images/tech.jpg";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Process from "./Home/Process_";
import Why from "./Home/Why";
import axios from "axios";
import { Link } from "react-router-dom";
import ItemDesign from "./Design/ItemDesign";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class BrandingCate extends Component{
    constructor(props) {
        super(props);
        this.state = {
            designs:  [],
            cates:  [],
            pagi: []
        }
    }
    componentDidMount() {
        axios.get(`https://api.takecare.vn/list-branding-cate/?cate=`+this.props.match.params.slug)
            .then(res => {
                const designs = res.data;
                this.setState({ designs: designs });
                if(designs.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/category-design/`)
            .then(res => {
                const cates = res.data;
                this.setState({ cates: cates });
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/count-cate-branding/?cate=`+this.props.match.params.slug)
            .then(res => {
                const total = res.data;
                const arr_list = [];
                for(var i = 1; i <= total; i++){
                    arr_list.push(i);
                }
                this.setState({pagi: arr_list});
            })
            .catch(error => console.log(error));
    }

    render() {
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_project branding">
                {this.state.designs.map((design, key) => (
                    <Helmet key={key}>
                        <title>{design.cat}</title>
                        <meta name="description" content="Một bộ nhận diện thương hiệu chuyên nghiệp giúp cho khách hàng ấn tượng và đặt trọn niềm tin khi lựa chọn doanh nghiệp của bạn. Hãy để chúng tôi tạo ra nó giúp bạn." />
                        <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={design.cat} />
                        <meta property="og:description" content="Một bộ nhận diện thương hiệu chuyên nghiệp giúp cho khách hàng ấn tượng và đặt trọn niềm tin khi lựa chọn doanh nghiệp của bạn. Hãy để chúng tôi tạo ra nó giúp bạn." />
                        <meta property="og:image" content={Logo} />
                    </Helmet>
                ))}


                <div className="banner_page"  style={{ background: 'url(' + Image + ')' }}>
                    <div className="container">
                        <div className="in">
                            <h1>Thương hiệu</h1>
                            <div className="breadcrums">
                                <ul>
                                    <li><a href="/">Trang chủ</a></li>
                                    <li>/</li>
                                    <li><span>Thương hiệu</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="list_brand_menu">
                        <ul>
                            <li><Link to="/thuong-hieu">TẤT CẢ</Link></li>
                            {this.state.cates.map((cate, key) => (
                                this.props.match.params.slug === cate.slug
                                ? (<li key={key} data-slug={cate.slug} className="active">
                                        <Link to={'/thuong-hieu/'+cate.slug} onClick={LoadPage}>{cate.title}</Link>
                                    </li>)
                                : (<li key={key} data-slug={cate.slug}>
                                        <Link to={'/thuong-hieu/'+cate.slug} onClick={LoadPage}>{cate.title}</Link>
                                    </li>)
                            ))}
                        </ul>
                    </div>
                    <div className="list_brand">
                        {this.state.designs.map((design, key) => (
                            <ItemDesign
                            key={key}
                            title={design.title}
                            slug={design.link}
                            image={design.image}
                            />
                        ))}
                    </div>

                    <div className="pagi">
                        <ul>
                            {this.state.pagi.map((item, key) => (
                                key <= 0
                                    ? (<li key={item}><span>{key + 1}</span></li>)
                                    : <li key={item}><Link to={'/thuong-hieu/'+this.props.match.params.slug+'/page/'+(key+1)} onClick={LoadPage}>{key+1}</Link></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        )
    }
}
