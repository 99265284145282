import React, {Component} from 'react';
import {Link} from "react-router-dom";
import PlaceHolder from "../../../images/place-holder.png";
import LazyImage from "../../Base/Lazy";

export default class ItemDesign extends Component{
    render() {
        return (
            <div className="item" >
                <Link to={'/branding/'+this.props.slug}>
                    <LazyImage
                        placeHolder={PlaceHolder}
                        src={this.props.image}
                        width={`100%`}
                        height={`auto`}
                        effect={"opacity"}
                        alt={this.props.title}
                    />
                    <h3><span>{this.props.title} <i className="fa fa-eye" aria-hidden="true"></i></span></h3>
                </Link>
            </div>
        );
    }
}
