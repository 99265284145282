import React, {Component} from 'react';
import helpers from "../Event/helpers";
import axios from "axios";
import PlaceHolder from "../../images/place-holder.png";
import LazyImage from "./Lazy";

export default class PopupForm extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            email: '',
            tel: '',
            service: '',
            note: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeFullName = event => {
        this.setState({ fullname: event.target.value })
    }

    handleChangeEmail = event => {
        this.setState({ email: event.target.value })
    }

    handleChangeTel = event => {
        this.setState({ tel: event.target.value })
    }

    handleChangeService = event => {
        this.setState({ service: event.target.value })
    }

    handleChangeNote = event => {
        this.setState({ note: event.target.value })
    }

    async handleSubmit(e) {
        e.preventDefault();

        var data = new FormData();

        if(this.state.fullname != ''){
            data.append('fullname', this.state.fullname);
        }else{
            let slt = document.querySelector('.popup_form ul li input.name');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Tên bị trống !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        if(this.state.email != ''){
            function validateEmail(email)
            {
                var re = /\S+@\S+\.\S+/;
                return re.test(email);
            }

            if(validateEmail(this.state.email) == true){
                data.append('email', this.state.email);
            }else{
                let slt = document.querySelector('.popup_form ul li input.email');
                slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Email không hợp lệ !</p>');
                setTimeout(function (){
                    document.querySelector('.empty_txt').remove();
                },1500);
            }
        }else{
            let slt = document.querySelector('.popup_form ul li input.email');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Email bị trống !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        if(this.state.tel != ''){
            function telephoneCheck(str) {
                return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(str);
            }
            if(telephoneCheck(this.state.tel) == true){
                data.append('tel', this.state.tel);
            }else{
                let slt = document.querySelector('.popup_form ul li input.tel');
                slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Số điện thoại không hợp lệ !</p>');
                setTimeout(function (){
                    document.querySelector('.empty_txt').remove();
                },1500);
            }
        }else{
            let slt = document.querySelector('.popup_form ul li input.tel');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Số điện thoại bị trống !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        if(this.state.service != ''){
            data.append('service', this.state.service);
        }else{
            let slt = document.querySelector('.popup_form ul li select');
            slt.insertAdjacentHTML('afterend', '<p class="empty_txt">Chưa chọn dịch vụ !</p>');
            setTimeout(function (){
                document.querySelector('.empty_txt').remove();
            },1500);
        }

        data.append('note', this.state.note);

        if(this.state.fullname != '' && this.state.email != '' && this.state.tel != '' && this.state.service != ''){
            data.append('send', 'true');
            data.append('form', 'Form popup');
            axios.post('https://api.takecare.vn/form-data/', data)
                .then(response => {
                    //console.log(response)
                    //this.filter = response.data
                })
                .catch(e => {
                    //console.log(e)
                });
            this.setState({fullname: ''});
            this.setState({email: ''});
            this.setState({tel: ''});
            this.setState({service: ''});
            this.setState({note: ''});

            document.querySelector('.popup_form ul li input.name').value = '';
            document.querySelector('.popup_form ul li input.email').value = '';
            document.querySelector('.popup_form ul li input.tel').value = '';
            document.querySelector('.popup_form ul li select.dv').value = '';
            document.querySelector('.popup_form ul li textarea.khac').value = '';
            document.querySelector('.popup_form').classList.remove('ac');
            document.querySelector('.thankyou').classList.add('active');

            function setCookie (key, value, days) {
                var date = new Date();

                // Default at 365 days.
                days = days || 365;

                // Get unix milliseconds at current time plus number of days
                date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000

                window.document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";

                return value;
            };

            let getCookie = function(name) {
                var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
                return r ? r[1] : null;
            };

            if(getCookie('checkform') == 'NaN'){
                setCookie('checkform', 1, 1);
            }else{
                let n = parseInt(getCookie('checkform')) + 1;
                setCookie('checkform', n, 1);
            }
        }
    }

    render() {
        function closePopupContact(){
            helpers.ClosePopupContact_();
        }
        let getCookie = function(name) {
            var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
            return r ? r[1] : null;
        };
        return (
            <div className="popup_form">
                <form onSubmit={this.handleSubmit}>
                    <div className="in">
                        <div className="first">
                            <LazyImage
                                placeHolder={PlaceHolder}
                                src="https://api.takecare.vn/takecare/files/2021/03/Mobile.2.png"
                                width={`100%`}
                                height={`auto`}
                                effect={"opacity"}
                                alt="Điền thông tin tư vấn"
                            />
                            <h3>Thông tin tư vấn</h3>
                        </div>

                        <div className="content">
                            <ul>
                                <li>
                                    <label>Họ Và Tên</label>
                                    <input type="text" className="name" onChange={this.handleChangeFullName}/>
                                </li>
                                <li>
                                    <label>Email</label>
                                    <input type="text" className="email" onChange={this.handleChangeEmail}/>
                                </li>
                                <li>
                                    <label>Số điện thoại</label>
                                    <input type="text" className="tel" onChange={this.handleChangeTel}/>
                                </li>
                                <li>
                                    <label>Dịch vụ:</label>
                                    <select className="dv" onChange={this.handleChangeService}>
                                        <option value="">Chọn dịch vụ tư vấn</option>
                                        <option value="Thiết kế website">Thiết kế website</option>
                                        <option value="Thiết kế logo">Thiết kế logo</option>
                                        <option value="SEO website">SEO website</option>
                                        <option value="Chạy quảng cáo">Chạy quảng cáo</option>
                                        <option value="Viết bài nội dung">Viết bài nội dung</option>
                                        <option value="Khác">Khác</option>
                                    </select>
                                </li>
                                <li>
                                    <label>Ghi chú thêm</label>
                                    <textarea className="khac" onChange={this.handleChangeNote}></textarea>
                                </li>
                                <li>
                                    {(() => {
                                        if (getCookie('checkform') >= 2) {
                                            return (
                                                <button type="button" className="send" name="send" style={{backgroundColor:'#eaeaea'}}>
                                                    Tư vấn ngay
                                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                </button>
                                            )
                                        } else {
                                            return (
                                                <button type="submit" className="send" name="send" onClick={this.handleSubmit}>
                                                    Tư vấn ngay
                                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                </button>
                                            )
                                        }
                                    })()}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="close_popup_form" onClick={closePopupContact}></div>
                </form>
            </div>
        );
    }
}
