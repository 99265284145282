import React, {Component} from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import LazyImage from "../../Base/Lazy";
import PlaceHolder from "../../../images/place-holder.png";

export default class Slider extends Component{
    constructor(props) {
        super(props);
        this.state = {
            slider:  [],
            class: ''
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/banner-slider-home/`)
            .then(res => {
                const slider = res.data;
                this.setState({ slider: slider });
            })
            .catch(error => console.log(error));
    }

    render() {
        SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectFade]);

        return (
            <div className="slider">
                {
                    this.state.slider != ''
                        ? (<Swiper
                            onSlideChange={() => {
                                this.setState({class:'active'});
                            }}
                            effect="fade"
                            loop={true}
                            spaceBetween={50}
                            slidesPerView={1}
                            autoplay={{delay: 5000}}
                            navigation={{clickable: true}}
                            pagination={{clickable: true}}
                            scrollbar={{draggable: true}}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 1,
                                },
                                1000: {
                                    slidesPerView: 1,
                                },
                            }}
                        >
                            {this.state.slider.map((item, key) => (
                                <SwiperSlide key={key}>
                                    <img className="pc" src={item.image} alt={item.title}/>
                                    <img className="mobile" src={item.mobile} alt={item.title}/>
                                    <span className={"mockup "+this.state.class}>
                                        <LazyImage
                                            placeHolder={PlaceHolder}
                                            src={item.mockup}
                                            width={`100%`}
                                            height={`auto`}
                                            effect={"opacity"}
                                            alt={item.title}
                                        />
                                    </span>
                                    <div className="txt">
                                        <div className="container">
                                            <div className="group">
                                                <div className="title">
                                                    <h2 className={this.state.class}>{item.title}</h2>
                                                    <ul>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <p className={this.state.class}>{item.des}</p>
                                                <div>
                                                    <a href={item.link} className={this.state.class}>
                                                        <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                                                        Xem chi tiết
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>)
                        : (<div></div>)
                }
            </div>
        );
    }
}
