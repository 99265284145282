import React, {Component} from 'react';
//import Image from '../../../images/nene.png';
import Icon1 from '../../../images/1cut.png';
import Icon2 from '../../../images/2cut.png';
import Icon3 from '../../../images/3cut.png';
import Icon4 from '../../../images/4cut.png';
import Icon5 from '../../../images/5cut.png';
import helpers from "../../Event/helpers";
import PlaceHolder from "../../../images/place-holder.png";
import LazyImage from "../../Base/Lazy";

export default class Process extends Component{
    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        // style={{ background: 'url(' + Image + ')' }}
        return (
            <div className="process">
                <div className="container">
                    <div className="title">
                        <h2>Quy trình triển khai dự án</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="list_process">
                        <ul>
                            <li>
                                <div className="number">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={Icon1}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt="icon"
                                    />
                                </div>
                                <div className="txt">Tư vấn dịch vụ</div>
                            </li>
                            <li>
                                <div className="number">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={Icon2}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt="icon"
                                    />
                                </div>
                                <div className="txt">Chốt hợp đồng và tạm ứng kinh phí</div>
                            </li>
                            <li>
                                <div className="number">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={Icon3}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt="icon"
                                    />
                                </div>
                                <div className="txt">Triển khai thi công dịch vụ</div>
                            </li>
                            <li>
                                <div className="number">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={Icon4}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt="icon"
                                    />
                                </div>
                                <div className="txt">Bàn giao và nhiệm thu</div>
                            </li>
                            <li>
                                <div className="number">
                                    <LazyImage
                                        placeHolder={PlaceHolder}
                                        src={Icon5}
                                        width={`100%`}
                                        height={`auto`}
                                        effect={"opacity"}
                                        alt="icon"
                                    />
                                </div>
                                <div className="txt">Hỗ trợ 24/7 sau nhiệm thu</div>
                            </li>
                        </ul>
                    </div>

                    <div className="btn">
                        <i className="fa fa-angle-double-down" aria-hidden="true"></i>
                        <a href="/" className="popup_contact" onClick={PopupContact}><i className="fa fa-envelope-open" aria-hidden="true"></i> Tư vấn ngay</a>
                    </div>
                </div>
            </div>
        );
    }
}
