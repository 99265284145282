import React, { Component } from 'react';
import LazyImage from "../../Base/Lazy";
import PlaceHolder from "../../../images/place-holder.png";
import axios from "axios";
import {Link} from "react-router-dom";

export default class Blog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            posts:  [],
        }
    }
    componentDidMount() {
        axios.get(`https://api.takecare.vn/home-blog/`)
            .then(res => {
                const posts = res.data;
                this.setState({ posts: posts });
            })
            .catch(error => console.log(error));
    }

    render() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="blog">
                <div className="container">
                    <div className="title">
                        <h2>Tin tức</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            {this.state.posts.map((post, key) => (
                            <div className="item" key={key}>
                                <Link to={'tin-tuc/'+post.link}>
                                    <div className="insider">
                                        <div className="img">
                                            <LazyImage
                                                placeHolder={PlaceHolder}
                                                src={post.image}
                                                width={`100%`}
                                                height={`auto`}
                                                effect={"opacity"}
                                                alt={post.title}
                                            />
                                        </div>
                                        <div className="date">
                                            <span>{post.date1}</span>
                                            <span>{post.date2}</span>
                                        </div>
                                        <div className="content">
                                            <h3>{post.title}</h3>
                                            <div className="cate">
                                                <i className="fa fa-tags" aria-hidden="true"></i>
                                                {post.cat}
                                            </div>
                                            <p>{post.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            ))}
                        </div>

                        <div className="btn">
                            <Link to="/tin-tuc"><i className="fa fa-plus" aria-hidden="true"></i> Xem thêm</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


