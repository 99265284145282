import React, {Component} from 'react';
import LazyImage from "../../Base/Lazy";
import PlaceHolder from "../../../images/place-holder.png";
import ImageAside from "../../../images/aside.png";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import BlogRelated from "./SingleBlog/BlogRelated";
import axios from "axios";
import Parser from 'html-react-parser';
import {Link} from "react-router-dom";
import ContactHome from "../Home/ContactHome";
import FromSideBar from "../../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Logo from "../../../images/logo.png";

export default class SingleBlog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            posts:  [],
            related: []
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/`+this.props.match.params.slug)
            .then(res => {
                const posts = res.data;
                this.setState({ posts: posts });
            })
            .catch(error => console.log(error));
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;


        axios.get(`https://api.takecare.vn/related-blog/?slug=`+this.props.match.params.slug)
            .then(res => {
                const posts = res.data;
                this.setState({ related: posts });
                if(posts.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
    }

    render() {
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        return (
            <div className="single_blog">
                {this.state.posts.map((post, key) => (
                    <Helmet key={key}>
                        <title>{post.title}</title>
                        <meta name="description" content={post.description} />
                        <meta name="keywords" content={post.tags} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={post.title} />
                        <meta property="og:description" content={post.description} />
                        <meta property="og:image" content={Logo} />
                    </Helmet>
                ))}

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            {this.state.posts.map((post, key) => (
                                <li key={key}><Link to={"/"+post.cat_slug} onClick={LoadPage}>{post.cat}</Link></li>
                            ))}
                            <li>/</li>
                            <li><span>Bật mí 7 cách tối ưu chạy ads Facebook giá rẻ </span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    {this.state.posts.map((post, key) => (
                                        <div key={post}>
                                            <h1>{post.title}</h1>
                                            {Parser(post.content)}
                                        </div>
                                    ))}
                                </div>

                                <LazyLoadComponent>
                                    <div className="title">
                                        <h2 className="title_ralated">Bài viết liên quan</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className="blog_related">
                                        <div className="list_blog">
                                            <div className="in">
                                                {this.state.related.map((post, key) => (
                                                    <BlogRelated
                                                        key={key}
                                                        slug={post.link}
                                                        img={post.image}
                                                        alt={post.title}
                                                        title={post.title}
                                                        content={post.description}
                                                        moth_day={post.date1}
                                                        year={post.date2}
                                                        cat={post.cat}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </LazyLoadComponent>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoadComponent>
                    <ContactHome />
                </LazyLoadComponent>
            </div>
        );
    }
}
