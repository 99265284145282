import React, {Component} from 'react';
import AboutHome from "./Home/AboutHome";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import ContactHome from "./Home/ContactHome";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class About extends Component{
    render() {
        return (
            <div className="home">
                <Helmet>
                    <title>Vể chúng tôi</title>
                    <meta name="description" content="Chúng tôi luôn dành toàn bộ tâm huyết và tận tình đến với từng khách hàng khi hợp tác với Takecare Agency, Chúng tôi cam kết sẽ bàn giao những sản phẩm dịch vụ với chất lượng tốt nhất đến với từng khách hàng." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Vể chúng tôi" />
                    <meta property="og:description" content="Chúng tôi luôn dành toàn bộ tâm huyết và tận tình đến với từng khách hàng khi hợp tác với Takecare Agency, Chúng tôi cam kết sẽ bàn giao những sản phẩm dịch vụ với chất lượng tốt nhất đến với từng khách hàng." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <h1 style={{position:"absolute",opacity:0,top:'-1000px',}}>Vể chúng tôi</h1>
                <LazyLoadComponent>
                    <AboutHome />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <div className="we_say">
                        <div className="container">
                            <div className="list">
                                <div className="item">
                                    <div className="title">
                                        <h2>TẦM NHÌN</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <p>Mọi thứ chúng tôi đang làm, chúng tôi tin rằng chúng tôi tạo ra sự <strong> HOÀN HẢO </strong></p>
                                </div>
                                <div className="item">
                                    <div className="title">
                                        <h2>SỨ MỆNH</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <p>Cách chúng tôi đối mặt với thử thách là tạo ra sản phẩm tuyệt đẹp, dễ sử dụng và thân thiện với người dùng</p>
                                </div>
                                <div className="item">
                                    <div className="title">
                                        <h2>NHỮNG ĐIỀU CHÚNG TÔI TIN TƯỞNG</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>

                                    <ul className="ul">
                                        <li>
                                            <h3>Là một người tốt :</h3>
                                            <p>Những hành động của bạn tạo nên giá trị con người bạn . cách bạn làm một việc là cách bạn làm mọi việc.</p>
                                        </li>
                                        <li>
                                            <h3>Tâm huyết và trách nhiệm :</h3>
                                            <p>Có tâm ắt có tầm : một con người luôn nhiệt huyết với công việc mình đang làm luôn tạo ra tầm ảnh hưởng lớn</p>
                                        </li>
                                        <li>
                                            <h3>Sáng tạo, khác biệt, thay đổi :</h3>
                                            <p>Giữ gìn bản sắc vốn có , tích lũy kế thừa đồng thời không ngừng sáng tạo vận dụng những cái mới tạo ra sự khác
                                                biệt mang xu hướng đi đầu và tiên phong.</p>
                                        </li>
                                        <li>
                                            <h3>Đơn giản nhưng truyền cảm hứng</h3>
                                            <p>Mỗi sản phẩm chúng tôi tạo ra đều bỏ vào đó là những câu chuyện đầy cảm xúc được tạo ra từ
                                                những con người tâm huyết</p>
                                        </li>
                                        <li>
                                            <h3>Tiện ích , trải nghiệm</h3>
                                            <p>Là điều mà chúng tôi luôn hướng tới sản phẩm thân thiện ,
                                                dễ sử dụng , và rất tiện lợi nhằm phục vụ tối đa cho người sử dụng .</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Process />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Why />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Partners />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <ContactHome />
                </LazyLoadComponent>
            </div>
        );
    }
}
