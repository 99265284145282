import React, {Component} from 'react';
import helpers from "../../Event/helpers";
import { Link } from "react-router-dom";

export default class Service extends Component{
    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        function BackTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
        return (
            <div className="service">
                <div className="container">
                    <div className="title">
                        <h2>Dịch vụ</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="list_service">
                        <div className="in">
                            <div className="item">
                                <div className="insider">
                                    <div className="box">
                                        <i className="fa fa-code" aria-hidden="true"></i>
                                        <h3>WEBSITE & PHẦN MỀM</h3>
                                    </div>
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                    <div className="box">
                                        <ul>
                                            <li><Link to="/dich-vu/thiet-ke-web" onClick={BackTop}>Thiết kế web theo thương hiệu</Link></li>
                                            <li><Link to="/dich-vu/thiet-ke-web" onClick={BackTop}>Thiết kế web trọn gói</Link></li>
                                            <li><Link to="/dich-vu/thiet-ke-web" onClick={BackTop}>Thiết kế phần mềm quản lý</Link></li>
                                            <li><Link to="/dich-vu/cham-soc-website" onClick={BackTop}>Chăm sóc website</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="insider">
                                    <div className="box">
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <h3>TƯ VẤN THƯƠNG HIỆU</h3>
                                    </div>
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                    <div className="box">
                                        <ul>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Đặt tên thương hiệu</a></li>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Đặt tên công ty</a></li>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Tư vấn làm mới thương hiệu</a></li>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Bảo hộ thương hiệu</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="insider">
                                    <div className="box">
                                        <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                                        <h3>THIẾT KẾ THƯƠNG HIỆU</h3>
                                    </div>
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                    <div className="box">
                                        <ul>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Thiết kế logo</a></li>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Thiết kế nhận diện thương hiệu</a></li>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Thiết kế bao bì, nhãn mác</a></li>
                                            <li><a href="/dich-vu/thiet-ke-logo" onClick={BackTop}>Thiết kế Profile, Catalogue</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="insider">
                                    <div className="box">
                                        <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                                        <h3>DỊCH VỤ MARKETING</h3>
                                    </div>
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                    <div className="box">
                                        <ul>
                                            <li><Link to="/dich-vu/quang-cao-google" onClick={BackTop}>Quảng cáo google</Link></li>
                                            <li><Link to="/dich-vu/quang-cao-facebook" onClick={BackTop}>Quảng cáo facebook</Link></li>
                                            <li><Link to="/dich-vu/seo-website" onClick={BackTop}>Seo website</Link></li>
                                            <li><Link to="/dich-vu/bien-soan-noi-dung" onClick={BackTop}>Biên soạn content, nội dung</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="btn">
                        <i className="fa fa-angle-double-down" aria-hidden="true"></i>
                        <a href="/" className="popup_contact" onClick={PopupContact}><i className="fa fa-envelope-open" aria-hidden="true"></i> Tư vấn ngay</a>
                    </div>
                </div>
            </div>
        );
    }
}
