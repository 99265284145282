import React, {Component} from 'react';
import LazyImage from "../../Base/Lazy";
import PlaceHolder from "../../../images/place-holder.png";
import axios from "axios";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Parser from "html-react-parser";
import helpers from "../../Event/helpers";

export default class Why extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tests:  []
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/client-review/`)
            .then(res => {
                const tests = res.data;
                this.setState({ tests: tests });
            })
            .catch(error => console.log(error));
    }
    render() {
        SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }

        /*function funcPrev(){
            document.querySelector('.swiper-button-prev').click();
            document.querySelector('.swiper-button-next').click();
        }*/

        return (
            <div className="why">
                <div className="container">
                    <div className="title">
                        <h2>Tại sao lại chọn chúng tôi ?</h2>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="list_why">
                        <div className="in">
                            <div className="item">
                                <div className="insider">
                                    <i className="fa fa-handshake-o" aria-hidden="true"></i>
                                    <h3>KHÁCH TIN TƯỞNG</h3>
                                    <p>1000+</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="insider">
                                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                                    <h3>DỰ ÁN HOÀN THÀNH</h3>
                                    <p>1000+</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="insider">
                                    <i className="fa fa-users" aria-hidden="true"></i>
                                    <h3>NHÂN LỰC TÀI NĂNG</h3>
                                    <p>20</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="insider">
                                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                                    <h3>NĂM KINH NGHIỆM</h3>
                                    <p>6</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="testimonials">
                        {
                            this.state.tests != ''
                            ? (<Swiper
                                    loop={true}
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    autoplay={{ delay: 5000 }}
                                    navigation={{ clickable: true }}
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 1,
                                        },
                                        1000: {
                                            slidesPerView: 3,
                                        },
                                    }}
                                >
                                    {this.state.tests.map((item, key) => (
                                        <SwiperSlide key={key}>
                                            <div className="insider">
                                                <div className="info">
                                                    <div className="avatar">
                                                        <LazyImage
                                                            placeHolder={PlaceHolder}
                                                            src={item.image}
                                                            width={`100%`}
                                                            height={`auto`}
                                                            effect={"opacity"}
                                                            alt={item.title}
                                                        />
                                                    </div>
                                                    <div className="content_info">
                                                        <div className="name">{item.title}</div>
                                                        <div className="pos">{item.position}</div>
                                                    </div>
                                                </div>

                                                <div className="content">
                                                    {Parser(item.content)}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>)
                            : (<div></div>)
                        }
                    </div>
                </div>
                <div className="btn">
                    <i className="fa fa-angle-double-down" aria-hidden="true"></i>
                    <a href="/" className="popup_contact" onClick={PopupContact}><i className="fa fa-envelope-open" aria-hidden="true"></i> Tư vấn ngay</a>
                </div>
            </div>
        );
    }
}
