import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class Content extends Component{
    render() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Biên soạn nội dung</title>
                    <meta name="description" content="Chúng tôi cung cấp dịch vụ biên soạn nội dung cho website: viết bài chuẩn SEO, biên tập nội dung, thiết kế hình ảnh tối ưu SEO để đảm bảo cải thiện vị trí từ khoá cho website trên công cụ tìm kiếm." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Biên soạn nội dung" />
                    <meta property="og:description" content="Chúng tôi cung cấp dịch vụ biên soạn nội dung cho website: viết bài chuẩn SEO, biên tập nội dung, thiết kế hình ảnh tối ưu SEO để đảm bảo cải thiện vị trí từ khoá cho website trên công cụ tìm kiếm." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Biên soạn nội dung</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Biên soạn nội dung</h1>
                                    <div className="big">
                                        <p>Để có thể viết được content thì trước hết bạn phải hiểu được content là gì? Content theo nghĩa Tiếng Việt là “Nội dung”,
                                            nội dung ở đây được hiểu là bao gồm tất cả những câu chữ, hình ảnh, âm thanh, video mang giá trị thông điệp, ý nghĩa
                                            nhất định mà người sáng tạo muốn truyền tải đến người đọc.</p>
                                        <p>Nếu SEO được coi là điều kiện quan trọng nhất dẫn đến thành công lấy sự thu hút, tăng tỉ lệ chuyển đổi, mở rộng mạng
                                            lưới khách hàng, thì Content lại là chìa khóa nắm giữ bí quyết tạo ra chất lượng,hấp dẫn, lôi cuốn người đọc và
                                            giữ chân họ ở lại. Mục đích của viết content sáng tạo nội dung, biến nội dung trở nên độc đáo thu hút sự chú ý
                                            của người đọc, lôi kéo họ tìm đọc thêm những nội dung hấp dẫn khác.</p>
                                        <h2>Những bước để biên soạn nội dung Content</h2>
                                        <h3>Xác định chủ để cần viết</h3>
                                        <p>Chắc chắn rồi bước đầu tiên chúng ta cần phải làm đó là xác định chủ đề cần viết. Để viết được nội dung hay và sắc
                                            sảo thì bạn cần phải hiểu những gì mình định viết, từ đó sẽ giúp bạn có những phân tích chi tiết, nội dung sát với
                                            chủ đề và sử dụng những ngôn từ chính xác hơn.</p>
                                        <p>Việc lên ý tưởng, tìm ra một chủ đề để xây dựng nội dung là một việc nhiều đắn đo và khó khăn nhất. Nhưng việc nào
                                            cũng sẽ có cách giải quyết được, bạn có thể tạo ra thật nhiều chủ đề khác nhau, sau đó chọn lọc từ đó ra chủ đề nào
                                            hay nhất, độc đáo nhất .</p>
                                        <p>Theo như mình biết được thì một số người viết content chuyên nghiệp hay những freelancer họ thường tìm ý tưởng từ
                                            cách áp dụng nguyên tắc 5W – 1H</p>
                                        <ul>
                                            <li>What: Viết về cái gì?</li>
                                            <li>When: Thời gian cho lĩnh vực mà bạn đang viết?</li>
                                            <li>Where: Bài viết khi viết xong sẽ được đăng ở đâu?</li>
                                            <li>Why: Lý do viết bài này là gì?</li>
                                            <li>Who: Bài viết này viết cho đối tượng nào đọc?</li>
                                            <li>How: Viết như thế nào để đạt được mục tiêu?</li>
                                        </ul>
                                        <h3>Xác định đối tượng</h3>
                                        <p>Bất cứ khi viết về một chủ đề nào thì cũng cần phải xác định đối tượng độc giả là ai? Bởi nếu không xác định được
                                            mục tiêu đọc giả là ai thì bạn sẽ không xác định được điều họ đang quan tâm là gì, vấn đề mà họ đang gặp phải là
                                            gì và họ đang mong muốn gì từ những chủ đề mà bạn đề ra.</p>
                                        <p>Khi bạn đã có thể xác định được đối tượng cần truyền tải đến những thông tin này là ai rồi, thì lúc này bạn đã
                                            giải quyết được 50% trên con đường xây dựng lên ý tưởng để viết một bài content rồi.</p>
                                        <LazyImage
                                            placeHolder={PlaceHolder}
                                            src="https://api.takecare.vn/takecare/files/2021/02/viet-bai-chuan-seo-300x167.jpg"
                                            width={`100%`}
                                            height={`auto`}
                                            effect={"opacity"}
                                            alt="Xác định đối tượng"
                                        />
                                        <h3>Tìm hiểu thu thập thông tin</h3>
                                        <p>Sau khi đã lên được ý tưởng cho chủ đề và xác định rõ nhóm đối tượng độc giả và những nội dung đã có qua quá trình thu thập thông tin,
                                            chắn hẳn lúc này bạn đã nắm bắt được phần nào những vấn đề mà người đọc quan tâm bây giờ bạn có thể hoàn toàn xác định được các
                                            mục tiêu cần đạt được để thỏa mãn người đọc.</p>
                                        <p>Lúc này điều bạn cần là nên liệt kê ra những mục tiêu mà bạn cần đạt được qua bài viết này và chọn lọc những mục tiêu then chốt,
                                            thực sự cần thiết để đưa vào bài viết. Không thì tối thiểu bạn cũng nên tự đánh giá các mục tiêu theo mức độ quan trọng của
                                            từng phần, từ đây bạn có thể phân chia sự tập trung và lượng thông tin cho phù hợp trong quá trình viết sau này.</p>
                                        <h3>Bắt tay vào viết</h3>
                                        <p>Có một thực tế là chúng ta vẫn không biết bắt đầu viết từ đâu dù chúng ta đã có đủ lượng thông tin cần thiết. Đối với một
                                            số người không chuyên thì có lẽ càng nhìn vào đống thông tin đã thu thập được thì càng rối phải không? Nếu bạn đang suy
                                            nghĩ như vậy thì cách giải quyết tốt nhất đó chính là hãy viết ra những gì đang nghĩ, chắc chắn bạn phải viết ra đừng
                                            chỉ mãi nghĩ ở trong đầu viết như thế nào, làm như thế sẽ tự tạo cho mình một ngõ cụt không thoát ra được.</p>
                                        <p>Bạn có thể viết theo dạng gạch đầu dòng giống như list ra một danh sách vậy, hoặc có thể bắt đầu viết về bất kì
                                            thông tin nào mà bạn đã thu thập được. Bạn hãy viết đi khi bạn bắt đầu viết thì kỹ năng viết tiềm ẩn trong người
                                            bạn sẽ được bộc phát và tự động giúp bạn hoàn thành nội dung content theo ý bạn muốn. Và hãy nhớ rằng trong quá
                                            trình viết tuyệt đối đừng để các thông tin khác làm bạn sao nhãng sự tập trung của bạn.</p>
                                        <h3>Chỉnh sửa và hoàn thiện</h3>
                                        <p>Từ một bản nội dung thô được tổng hợp từ các ý tưởng và thông tin thu thập ta đã có một bài viết tương đối có
                                            chiều sâu, tuy nhiên thường thì những bài viết này sẽ chưa có đủ sức thu hút, hấp dẫn. Lúc này ta sẽ dành thời gian
                                            để chỉnh sửa lại toàn bộ nội dung đã viết.</p>
                                        <p>Những việc cần làm trong quá trình kiểm tra:</p>
                                        <ul>
                                            <li>Chỉnh sửa lại lỗi chính tả và dấu câu</li>
                                            <li>Tối ưu lại định dạng bài viết sao cho dễ đọc, dễ hiểu</li>
                                            <li>Sửa lại các thuật ngữ khô cứng trở nên mền mại</li>
                                            <li>Biến các từ ngữ chuyên môn thành cụm từ đại trà, thân thuộc</li>
                                            <li>Đọc lại một lần xem cách hành văn có xuôi không</li>
                                        </ul>
                                        <LazyImage
                                            placeHolder={PlaceHolder}
                                            src="https://api.takecare.vn/takecare/files/2021/02/images-6-300x151.jpg"
                                            width={`100%`}
                                            height={`auto`}
                                            effect={"opacity"}
                                            alt="Chỉnh sửa và hoàn thiện"
                                        />
                                        <h2>Cách giúp cho viết bài trở nên độc đáo hơn</h2>
                                        <p>Khi bạn đã viết bài xong rồi thì vấn đề tiếp theo bạn sẽ thắc mắc đó chính là làm thế nào để viết hay, hấp dẫn,
                                            độc đáo thu hút người đọc.</p>
                                        <p>Dưới đây sẽ là một số mẹo để giúp bạn giải quyết những thắc mắc đó:</p>
                                        <h3>Bắt đầu bằng những câu hỏi</h3>
                                        <p>Tâm lý người đọc luôn khi đi tìm kiếm một bài viết luôn có những câu hỏi trong đầu. Vì thế khi bạn bắt đầu
                                            bài viết bằng những câu hỏi thì sẽ vô tình ăn khớp cùng với những suy nghĩ của người đọc. Lúc này họ sẽ nhanh
                                            chóng cảm nhận được rằng bài viết này có thể trực tiếp giải quyết được vấn đề mà họ đang mắc phải, từ đó bài
                                            viết cũng được gia tăng sự chú ý hơn.</p>
                                        <p>Trong phần tiêu đề nếu có thể bạn nên sử dụng các từ như “tại sao” và “như thế nào”. “Tại sao” thường được
                                            dùng cho những bài viết mang đến tính thuyết phục cho người dùng, “như thế nào” đánh động chung đến tâm lí
                                            của độc giả là tìm giải pháp cho các vấn đề trong cuộc sống.</p>
                                        <p>Cả hai từ trên được dùng trong những trường hợp khác nhau, tuy nhiên đều có thể thu hút sự chú ý của
                                            người đọc hiệu quả hơn những câu hỏi thông thường.</p>
                                        <h3>Tạo sự tò mò cho người đọc</h3>
                                        <p>Con người có tính tò mò và bị thu hút bởi những sự vật hay sự kiện, giống như một người đi đường bị
                                            ngã nhưng mọi người đều lại “tò mò” xem có chuyện gì.</p>
                                        <p>Đây cũng là cách mà các bài báo mạng hay dùng cho bài viết của mình như là ” Bắt gặp chồng đưa tình nhân
                                            vào nhà nghỉ và cái kết”. Hẳn là khi ai đọc tiêu đề như thế này đều có thể hình dung được “cái kết”
                                            ở đây rồi, nhưng mọi người hầu hết đều sẽ tò mò xem cái kết đó có giống với suy nghĩ của mình hay không.
                                            Đây là tính tò mò của con người vốn không thể bỏ được.</p>
                                        <p>Thông thường để gây tò mò trong tiêu đề từ hay được dùng là ” bí mật”, nó sẽ khiến người đọc chú ý
                                            và nhấp vào để giải tỏa tính tò mò.</p>
                                        <h3>Gợi mở về những thứ họ sẽ nhận được</h3>
                                        <p>Việc hé lộ trước thứ mà người đọc sẽ nhận được trong bài viết cùng sẽ thu hút được người đọc.
                                            Cũng đã rất nhiều người áp dụng cách này và đạt được hiệu quả, ví dụ “Giảm 5kg trong 7 ngày mà
                                            không cần tập thể dục” hẳn là không ai lại không thích được nhận, bằng cách như thế này thể dễ
                                            dàng thu hút được người đọc rồi. Việc tiếp theo bạn chỉ cần là hé lộ từng việc hoặc từng bước một
                                            giúp người đọc có trọn được thứ mà họ muốn khi đã đọc bài viết của bạn.</p>
                                        <h3>Sử dụng các công thức</h3>
                                        <p>Có rất nhiều công thức bạn áp dụng cho bài viết của mình như:</p>
                                        <h4>Công thức PAS</h4>
                                        <ul>
                                            <li>Problem: Nói về vấn đề khách hang gặp phải</li>
                                            <li>Agitate: Làm quá, khoét sâu vào vấn đề để khách hàng cảm thấy nó quan trọng</li>
                                            <li>Solve: Đưa ra giải pháp cho khách hàng</li>
                                        </ul>
                                        <h4>Công thức AIDA</h4>
                                        <ul>
                                            <li>Attention: Gây sự chú ý</li>
                                            <li>Interest: Tạo sự hấp dẫn để khách hàng quan tâm nhiều hơn</li>
                                            <li>Desire: Tạo ra sự khát khao</li>
                                            <li>Action: Tạo nhu cầu để người đọc thực hiện hành động mua hàng hoặc tương tác với nội dung</li>
                                        </ul>
                                        <h4>Công thức 5W – 1H</h4>
                                        <p>Đây là công thức đơn giản nhất vì chỉ cần viết theo những ý của chữ cái đầu tiên đã có thể có được một bài viết chất lượng rồi</p>
                                        <ul>
                                            <li>What: Cái gì?</li>
                                            <li>Where: Ở đâu?</li>
                                            <li>When: Khi nào?</li>
                                            <li>Who: Cho ai?</li>
                                            <li>Why: Tại sao?</li>
                                            <li>How: Như thế nào?</li>
                                        </ul>

                                        <h2><strong>Liên hệ ngay với chúng tôi. Hotline: 0963.339.934</strong></h2>
                                        <p>Nếu có thể tìm được cho câu trả lời cho những câu hỏi trên thì bạn có thể giúp bạn dễ dàng hơn trong việc tìm
                                            ra ý tưởng, chủ đề cho một nội dung.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Partners />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
