import React, {Component} from 'react';
import BannerAbout from '../../../images/gt.png';
import PlaceHolder from "../../../images/place-holder.png";
import LazyImage from "../../Base/Lazy";

export default class AboutHome extends Component{
    render() {
        return (
            <div className="about_home">
                <div className="in">
                    <div className="item">
                        <LazyImage
                            placeHolder={PlaceHolder}
                            src={BannerAbout}
                            width={`100%`}
                            height={`auto`}
                            effect={"opacity"}
                            alt="Takecare Agency"
                        />
                    </div>

                    <div className="item">
                        <div className="insider">
                            <div className="title">
                                <h2>Về chúng tôi</h2>
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                            <div className="content">
                                <p><strong>Takecare Agency</strong> được thành lập với đội ngũ nhân viên
                                    dày dặn kinh nghiệm ( ~ 5 năm kinh nghiêm làm việc cho các công ty , tập đoàn lớn ) . Chúng tôi luôn dành toàn bộ
                                    tâm huyết và tận tình đến với từng khách hàng khi hợp tác với <strong>Takecare Agency</strong>, Chúng tôi cam kết
                                    sẽ bàn giao những sản phẩm dịch vụ với chất lượng tốt nhất đến với từng khách hàng .</p>
                                <p>SỨ MỆNH CỦA <strong>Takecare Agency</strong></p>
                                <p>– Mang đến những sản phẩm và dịch vụ tốt nhất đến với khách hàng .</p>
                                <p>– Xây dựng môi trường làm việc chuyên nghiệp-năng động-sáng tạo .</p>
                                <p>– Đội ngũ nhân viên được đào tạo theo tiêu chuẩn của Nhật Bản để đem lại chất lượng dịch vụ cũng như trải nghiệm tốt nhất
                                    cho khách hàng.</p>
                            </div>

                            <ul className="social">
                                <li><a href="https://www.facebook.com/Agency.Takecare"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
                                <li><a href="https://www.youtube.com/"><i className="fa fa-pinterest-p" aria-hidden="true"></i> </a></li>
                                <li><a href="https://gmail.com/"><i className="fa fa-google-plus" aria-hidden="true"></i> </a></li>
                                <li><a href="https://www.pinterest.com/"><i className="fa fa-youtube-play" aria-hidden="true"></i> </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
