import React, {Component} from 'react';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import LazyImage from "../Base/Lazy";
import PlaceHolder from "../../images/place-holder.png";
import ImageAside from "../../images/aside.png";
import helpers from "../Event/helpers";
import axios from "axios";
import {Link} from "react-router-dom";
import ContactHome from "./Home/ContactHome";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";

export default class websiteBasic extends Component{
    constructor(props) {
        super(props);
        this.state = {
            webs:  [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/website-demo/`)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
            })
            .catch(error => console.log(error));
    }
    render() {
        function PopupContact(e){
            helpers.PopupContact_();
            e.preventDefault();
        }
        return (
            <div className="single_blog page_basic">
                <Helmet>
                    <title>Thiết kế website giá rẻ</title>
                    <meta name="description" content="Takecare Agency hân hạnh ra mắt mảng website giá rẻ theo mẫu xây dựng sẵn. Với tiêu chí rẻ-mà-chất, rẻ nhưng vẫn phải sử dụng được. Chúng tôi không cung cấp website rác, website làm xong vất đi ..." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Thiết kế website giá rẻ</span></li>
                        </ul>
                    </div>

                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="content_single_blog">
                                    <h1>Thiết kế website giá rẻ</h1>
                                    <div className="big">
                                        <p>Trong kinh doanh, mọi hoạt động đều phải tính ra chi phí, tiêu chí tiết kiệm luôn được quan tâm hàng đầu.
                                            Trong đó, khi cần thiết kế website để phục vụ kinh doanh thì các đơn vị, cửa hàng,
                                            doanh nghiệp cũng cân nhắc rất khắc khe và lựa chọn đầu tiên luôn là thuê công ty thiết kế website giá rẻ.
                                            Để rồi một thời gian sau khi họ nhìn lại mới thấy mình chẳng thu được gì từ cái web giá rẻ ấy.</p>
                                        <p><strong>Takecare Agency</strong> hân hạnh ra mắt mảng website giá rẻ theo mẫu xây dựng sẵn. Với tiêu chí rẻ-mà-chất, rẻ
                                            nhưng vẫn phải sử dụng được. Các mẫu thiết kế website giá rẻ của chúng tôi là bước đầu cho bạn
                                            xây dựng doanh nghiệp hoặc bắt đầu buôn bán trôi trảy,
                                            tiền đề để phát triển lên một hệ thống chuyên nghiệp hơn. Chúng tôi không cung cấp website rác, website làm xong vất đi ...</p>
                                    </div>
                                    <h2 style={{textAlign: 'center',marginBottom:'30px',}}>Bảng giá dịch vụ</h2>

                                    <div className="pricing">
                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Basic</li>
                                                <li>Thời gian bàn giao 1 - 3 ngày <i className="fa fa-check-circle"
                                                                       aria-hidden="true"></i></li>
                                                <li>Lập trình bằng CMS mạnh mẽ, tùy biến cao <i className="fa fa-check-circle"
                                                                                                       aria-hidden="true"></i></li>
                                                <li>Theo mẫu có sẵn <i className="fa fa-check-circle"
                                                                       aria-hidden="true"></i></li>
                                                <li>Tặng tên miền <i className="fa fa-times-circle"
                                                                     aria-hidden="true"></i></li>
                                                <li>Hosting <i className="fa fa-times-circle"
                                                               aria-hidden="true"></i></li>
                                                <li>Đa nền tảng <i className="fa fa-check-circle"
                                                                   aria-hidden="true"></i></li>
                                                <li>Chuẩn Seo <i className="fa fa-check-circle"
                                                                   aria-hidden="true"></i></li>
                                                <li>SSL <i className="fa fa-times-circle"
                                                                 aria-hidden="true"></i></li>
                                                <li>Chat live/ nút call <i className="fa fa-check-circle"
                                                                 aria-hidden="true"></i></li>
                                                <li>Quảng bá thương hiệu <i className="fa fa-times-circle"
                                                           aria-hidden="true"></i></li>
                                                <li>Hướng dẫn quản trị <i className="fa fa-check-circle"
                                                                            aria-hidden="true"></i></li>
                                                <li>Có bảo hành <i className="fa fa-check-circle"
                                                                   aria-hidden="true"></i></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Pro</li>
                                                <li>Thời gian bàn giao 7 ngày <i className="fa fa-check-circle"
                                                                                 aria-hidden="true"></i></li>
                                                <li>Lập trình bằng CMS mạnh mẽ, tùy biến cao <i className="fa fa-check-circle"
                                                                                                aria-hidden="true"></i></li>
                                                <li>Theo yêu cầu <i className="fa fa-check-circle"
                                                                    aria-hidden="true"></i></li>
                                                <li>Tặng tên miền .com <i className="fa fa-check-circle"
                                                                          aria-hidden="true"></i></li>
                                                <li>Hosting: 2GB <i className="fa fa-check-circle"
                                                               aria-hidden="true"></i></li>
                                                <li>Đa nền tảng <i className="fa fa-check-circle"
                                                                   aria-hidden="true"></i></li>
                                                <li>Chuẩn Seo <i className="fa fa-check-circle"
                                                                 aria-hidden="true"></i></li>
                                                <li>SSL <i className="fa fa-check-circle"
                                                           aria-hidden="true"></i></li>
                                                <li>Chat live/ nút call <i className="fa fa-check-circle"
                                                                           aria-hidden="true"></i></li>
                                                <li>Quảng bá thương hiệu <i className="fa fa-times-circle"
                                                                            aria-hidden="true"></i></li>
                                                <li>Hướng dẫn quản trị <i className="fa fa-check-circle"
                                                                          aria-hidden="true"></i></li>
                                                <li>Có bảo hành <i className="fa fa-check-circle"
                                                                   aria-hidden="true"></i></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Premium</li>
                                                {/*<li className="active_price">9.000.000 VNĐ</li>*/}
                                                <li>Thời gian bàn giao 15 ngày <i className="fa fa-check-circle"
                                                                                 aria-hidden="true"></i></li>
                                                <li>Lập trình bằng CMS mạnh mẽ, tùy biến cao <i className="fa fa-check-circle"
                                                                                                aria-hidden="true"></i></li>
                                                <li>Theo yêu cầu <i className="fa fa-check-circle"
                                                                    aria-hidden="true"></i></li>
                                                <li>Tặng tên miền .com, .vn <i className="fa fa-check-circle"
                                                                                        aria-hidden="true"></i></li>
                                                <li>Hosting: 3GB <i className="fa fa-check-circle"
                                                                    aria-hidden="true"></i></li>
                                                <li>Đa nền tảng <i className="fa fa-check-circle"
                                                                   aria-hidden="true"></i></li>
                                                <li>Chuẩn Seo <i className="fa fa-check-circle"
                                                                 aria-hidden="true"></i></li>
                                                <li>SSL <i className="fa fa-check-circle"
                                                           aria-hidden="true"></i></li>
                                                <li>Chat live/ nút call <i className="fa fa-check-circle"
                                                                           aria-hidden="true"></i></li>
                                                <li>Quảng bá thương hiệu <i className="fa fa-check-circle"
                                                                            aria-hidden="true"></i></li>
                                                <li>Hướng dẫn quản trị <i className="fa fa-check-circle"
                                                                          aria-hidden="true"></i></li>
                                                <li>Có bảo hành <i className="fa fa-check-circle"
                                                                                  aria-hidden="true"></i></li>
                                                <li>
                                                    <a href="/" className="button" onClick={ PopupContact }>Báo giá</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <LazyLoadComponent>
                                    <div className="title">
                                        <h2 className="title_ralated">Giao diện demo</h2>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className="list_project">
                                        <div className="in">
                                            {this.state.webs.map((web, key) => (
                                            <div className="item" key={key}>
                                                <Link to={'/kho-giao-dien/'+web.link}>
                                                    <div className="img">
                                                        <LazyImage
                                                            placeHolder={PlaceHolder}
                                                            src={web.image}
                                                            width={`100%`}
                                                            height={`auto`}
                                                            effect={"opacity"}
                                                            alt={web.title}
                                                        />
                                                    </div>
                                                    <h3><span>{web.title}</span></h3>
                                                </Link>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </LazyLoadComponent>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <LazyLoadComponent>
                        <Process />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <Why />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContactHome />
                    </LazyLoadComponent>
                </div>
            </div>
        );
    }
}
