import React, {Component} from 'react';
import PlaceHolder from "../../images/place-holder.png";
import LazyImage from "../Base/Lazy";
import ImageAside from "../../images/aside.png";
import BlogItem from "./BlogList/BlogItem";
import axios from 'axios';
import {Link} from "react-router-dom";
import ContactHome from "./Home/ContactHome";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import FromSideBar from "../Base/FromSideBar";
import {Helmet} from "react-helmet";
import Logo from "../../images/logo.png";

export default class BlogList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            posts:  [],
            pagi: [],
        }
    }

    componentDidMount() {
        axios.get(`https://api.takecare.vn/blog/`)
            .then(res => {
                const posts = res.data;
                this.setState({ posts: posts });
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/count-blog/`)
            .then(res => {
                const total = res.data;
                const arr_list = [];
                for(var i = 1; i <= total; i++){
                    arr_list.push(i);
                }
                this.setState({pagi: arr_list});
            })
            .catch(error => console.log(error));
    }

    render() {
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return (
            <div className="blog_list">
                <Helmet>
                    <title>Tin tức | Các tin tức mới nhất của Takecare Agency</title>
                    <meta name="description" content="Các tin tức mới nhất của Takecare Agency nói về lĩnh vực thiết kế website, nhận diện thương hiệu, SEO website, biên soạn nội dung, chạy quảng cáo google, facebook ..." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Tin tức | Các tin tức mới nhất của Takecare Agency" />
                    <meta property="og:description" content="Các tin tức mới nhất của Takecare Agency nói về lĩnh vực thiết kế website, nhận diện thương hiệu, SEO website, biên soạn nội dung, chạy quảng cáo google, facebook ..." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="container">
                    <div className="breadcrums">
                        <ul>
                            <li><a href="/">Trang chủ</a></li>
                            <li>/</li>
                            <li><span>Tin tức</span></li>
                        </ul>
                    </div>

                    <div className="banner">
                        <div className="in">
                            <div className="img">
                                <LazyImage
                                    placeHolder={PlaceHolder}
                                    src={'https://api.takecare.vn/takecare/files/2021/02/qc9.jpg'}
                                    width={`100%`}
                                    height={`auto`}
                                    effect={"opacity"}
                                    alt="Takecare Agency"
                                />
                            </div>
                            <div className="img">
                                <LazyImage
                                    placeHolder={PlaceHolder}
                                    src={'https://api.takecare.vn/takecare/files/2021/02/5-mau-thiet-ke-brochure-dep-doc-la-nhat-nam-2020-1-768x457.jpg'}
                                    width={`100%`}
                                    height={`auto`}
                                    effect={"opacity"}
                                    alt="Takecare Agency"
                                />
                            </div>
                        </div>
                    </div>
                    <h1 style={{position:"absolute",top:"-1000px",opacity:'0'}}>Tin tức | Các tin tức mới nhất của Takecare Agency</h1>
                    <div className="list_blog">
                        <div className="in">
                            <div className="ite">
                                <div className="cate">
                                    <ul>
                                        <li className="active"><Link to="/tin-tuc">Tin tức</Link></li>
                                        <li><Link to="/khuyen-mai">Khuyến mãi</Link></li>
                                    </ul>
                                </div>

                                <div className="blogs">
                                    {this.state.posts.map((post, key) => (
                                        <BlogItem
                                            key={key}
                                            slug={post.link}
                                            img={post.image}
                                            alt={post.title}
                                            title={post.title}
                                            content={post.description}
                                            moth_day={post.date1}
                                            year={post.date2}
                                            cat={post.cat}
                                        />
                                    ))}
                                </div>

                                <div className="pagi">
                                    <ul>
                                        {this.state.pagi.map((item, key) => (
                                            key <= 0
                                                ? (<li key={item}><span>{key + 1}</span></li>)
                                                : <li key={item}><Link to={'/tin-tuc/page/'+(key+1)} onClick={LoadPage}>{key+1}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="ite">
                                <FromSideBar />
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoadComponent>
                    <ContactHome />
                </LazyLoadComponent>
            </div>
        );
    }
}
