import React, {Component} from 'react';
import Image from '../../images/tech.jpg';
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Why from "./Home/Why";
import Process from "./Home/Process_";
import {Link} from "react-router-dom";
import axios from "axios";
import ContactHome from "./Home/ContactHome";
import PlaceHolder from "../../images/place-holder.png";
import LazyImage from "../Base/Lazy";
import {Helmet} from "react-helmet";
import Partners from "./Home/Partners";
import Logo from "../../images/logo.png";

export default class ProjectCate extends Component{
    constructor(props) {
        super(props);
        this.state = {
            terms:  [],
            webs:  [],
            pagi: [],
            cat: [],
        }
    }
    componentDidMount() {
        axios.get(`https://api.takecare.vn/category-website/`)
            .then(res => {
                const terms = res.data;
                this.setState({ terms: terms });
            })
            .catch(error => console.log(error));

        axios.get(`https://api.takecare.vn/cate-web-demo/?cate=`+this.props.match.params.slug)
            .then(res => {
                const webs = res.data;
                this.setState({ webs: webs });
                if(webs.length <= 0){
                    document.location.href = "https://takecare.vn/404";
                }
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/count-cate-website/?cate=`+this.props.match.params.slug)
            .then(res => {
                const total = res.data;
                const arr_list = [];
                for(var i = 1; i <= total; i++){
                    arr_list.push(i);
                }
                this.setState({pagi: arr_list});
            })
            .catch(error => console.log(error));
        axios.get(`https://api.takecare.vn/get-cat-name/?cate=`+this.props.match.params.slug)
            .then(res => {
                const cat_name = res.data[0];
                this.setState({cat: cat_name});
            })
            .catch(error => console.log(error));
    }

    render() {
        function LoadPage(){
            setTimeout(function () {
                window.location.reload();
            });
        }
        return (
            <div className="project">
                <Helmet>
                    <title>{this.state.cat}</title>
                    <meta name="description" content="Kho giao diện đa dạng, phong phú với nhiều chủ đề lĩnh vực - nghành nghề khác nhau giúp cho khách hàng có được nhiều sự lựa chọn và phù hợp hơn với ngân sách có thể chi trả của mình." />
                    <meta name="keywords" content="thiet ke website, thiet ke web, thiet ke web chuyen nghiep, thiết kế web, nhận diện thương hiệu, thiết kế web giá rẻ, thiết kế web cao cấp, thiet ke web, nhan dien thuong hieu, chay quang cao, quang cao google, quang cao facebook, seo online, seo website, seo web, thiet ke phan mem, thiet ke app mobile, thiet ke app" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Seo website" />
                    <meta property="og:description" content="Chúng tôi chuyên cung cấp dịch vụ SEO website chất lượng, hiệu quả cao. Cam kết hiệu quả sau 6 tháng sử dụng dịch vụ từ khoá của bạn sẽ năm trong top đầu của công cụ tìm kiếm Google." />
                    <meta property="og:image" content={Logo} />
                </Helmet>

                <div className="banner_page"  style={{ background: 'url(' + Image + ')' }}>
                    <div className="container">
                        <div className="in">
                            <h1>{this.state.cat}</h1>
                            <div className="breadcrums">
                                <ul>
                                    <li><a href="/">Trang chủ</a></li>
                                    <li>/</li>
                                    <li><span>{this.state.cat}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="main_project">
                        <div className="side">
                            <aside>
                                <div className="title">
                                    <h2>Danh mục website</h2>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>

                                <div className="list_cate_product">
                                    <ul>
                                        {this.state.terms.map((term, key) => (
                                            this.props.match.params.slug === term.slug
                                                ? (<li key={key} data-id={term.slug} className="active">
                                                    <a href={"/"+term.slug}><i className={'fa '+term.icon}></i> {term.title}</a>
                                                </li>)
                                                : (<li key={key} data-id={term.slug}>
                                                    <a href={"/"+term.slug}><i className={'fa '+term.icon}></i> {term.title}</a>
                                                </li>)
                                        ))}
                                    </ul>
                                </div>
                            </aside>
                        </div>

                        <div className="list_project">
                            <div className="in">
                                {this.state.webs.map((web, key) => (
                                    <div className="item" key={key}>
                                        <a href={'/kho-giao-dien/'+web.link}>
                                            <div className="img">
                                                <LazyImage
                                                    placeHolder={PlaceHolder}
                                                    src={web.image}
                                                    width={`100%`}
                                                    height={`auto`}
                                                    effect={"opacity"}
                                                    alt={web.title}
                                                />
                                            </div>
                                            <h3><span>{web.title}</span></h3>
                                        </a>
                                    </div>
                                ))}
                            </div>

                            <div className="pagi">
                                <ul>
                                    {this.state.pagi.map((item, key) => (
                                        key <= 0
                                            ? (<li key={item}><span>{key + 1}</span></li>)
                                            : <li key={item}><Link to={'/'+this.props.match.params.slug+'/page/'+(key+1)} onClick={LoadPage}>{key+1}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoadComponent>
                    <Process />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Why />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Partners />
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <ContactHome />
                </LazyLoadComponent>
            </div>
        );
    }
}



